import React, {Fragment, useEffect} from 'react'
import{Button, Container, Table} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { useNavigate } from 'react-router-dom'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { listOrders} from '../../actions/orderActions'
import Footer from '../../components/Footer_/Footer'
import Header from '../../components/Header_/Header'


const OrderListScreen = () => {

    const dispatch = useDispatch()
    const history = useNavigate()

    const orderList = useSelector((state)=>state.orderList)
    const{loading, error, orders}= orderList

    const userLogin = useSelector((state)=>state.userLogin)
    const{userInfo}= userLogin
    
   
    useEffect(()=>{

        if(userInfo && userInfo.isAdmin){
                dispatch(listOrders())
                dispatch({type:'ORDER_DETAILS_RESET'})
            }
        else{
            history('/login')
        }
            }
            ,[dispatch, history, userInfo])
        

        
          return (
            <>
            <Header/>
            <Fragment>
                <Container className='admin-screens-mg-top'>
            <div>Orders</div>
            {loading ? <Loader/> : error ? <Message variant='danger'>{error}</Message>: (
        
        <Table striped bordered hover responsive className='table-sm'>
        <thead>
            <tr>
                    <th>ID</th>
                    <th>USER</th>
                    <th>DATE</th>
                    <th>TOTAL</th>
                    <th>PAID</th>
                    <th>DELIVERED</th>
                    <th></th>
            </tr>
        </thead>
        <tbody>
            {orders.map(order=>(
                <tr key={order._id}>
                    <td>{order._id}</td>
                    <td>{order.user && order.user.firstName}</td>
                    <td>{order.createdAt.substring(0, 10)}</td>
                    <td>${order.totalPrice}</td>

                    { order.paymentMethod==='Paypal or Credit or Debit' ? <td> {order.isPaid ? (order.paidAt.substring(0, 10) 
                    ) : (
                    <i className='fas fa-times' style={{color:'red'}}></i>
                    )}
                    </td> : <td>{order.isDelivered ? order.deliveredAt.substring(0, 10):(
              <i className='fas fa-times' style={{color:'red'}} />
            )}
            </td> }
                    <td>{order.isDelivered ? (order.deliveredAt.substring(0, 10) 
                    ) : (
                    <i className='fas fa-times' style={{color:'red'}}></i>)}
                    </td>
                    <td>
                        <LinkContainer to={`/order/${order._id}`}>
                            <Button variant='light' className='btn-sm'>
                               Details
                            </Button>
                        </LinkContainer>
                      
                    </td>
                </tr>
            ))}
        </tbody>
        
        </Table>
        
            )}
            </Container>
            </Fragment>
            <Footer/>
            </>
          )
}

export default OrderListScreen