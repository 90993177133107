import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./MainCategories.module.css";
import { Link } from "react-router-dom";

const MainCategoriesSec = () => {
  return (
    <div className="main">
      <Container>
        <h1 className="h1_main pb-4">— Shop By Brand —</h1>
        <Row className="gy-5 d-flex justify-content-center align-items-center">
          <Col lg={3}>
            <Link
              style={{ textDecoration: "none" }}
              to={"/shop/search/maria-b"}
            >
              <div className={styles.box}>
                <img src="/images/category_1.jpg" />
                <h4 className="h4_main">MARIA.B</h4>
              </div>
            </Link>
          </Col>
          <Col lg={3}>
            <Link
              style={{ textDecoration: "none" }}
              to={"/shop/search/qalamkar"}
            >
              <div className={styles.box}>
                <img src="/images/category_2.jpg" />
                <h4 className="h4_main">QALAMKAR</h4>
              </div>
            </Link>
          </Col>
          <Col lg={3}>
            <Link
              style={{ textDecoration: "none" }}
              to={"/shop/search/asimjofa"}
            >
              <div className={styles.box}>
                <img src="/images/category_3.jpg" />
                <h4 className="h4_main">ASIM JOFA</h4>
              </div>
            </Link>
          </Col>
          <Col lg={3}>
            <Link
              style={{ textDecoration: "none" }}
              to={"/shop/search/gulaal"}
            >
              <div className={styles.box}>
                <img src="/images/category_4.jpg" />
                <h4 className="h4_main">GULAAL</h4>
              </div>
            </Link>
          </Col>
          <Col lg={3}>
            <Link
              style={{ textDecoration: "none" }}
              to={"/shop/search/afrozeh"}
            >
              <div className={styles.box}>
                <img src="/images/category_5.jpg" />
                <h4 className="h4_main">AFROZEH</h4>
              </div>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MainCategoriesSec;
