import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer_/Footer";
import Header from "../../components/Header_/Header";
import "./Policies.css";

const PrivacyPolicyScreen = () => {
  return (
    <>
      <Header />
      <Container className="root">
        <div className="main">
          <h2 style={{ width: "100%" }} className="h2_main text-start pb-4">
            Privacy Policy
          </h2>
          <p className="para_main">
            Abqaree is committed to ensuring that your personal information is
            used properly and is kept securely. This Privacy Policy explains how
            we will collect and use your personal information through a website
            [www.abqaree.ca]. Please read our Privacy Policy carefully to get a
            clear understanding of how we collect, use, protect or otherwise
            handle your personal information. We will not sell or redistribute
            your information to anyone.
            <br />
            What personal information do we collect from the people that visit
            our website?
            <br />
            <br />
            When ordering or registering on our site, as appropriate, you may be
            asked to enter your name, email address, contact number, mailing
            address, credit card information or other details to help you with
            your experience.
            <br />
            <br />
            When do we collect information
            <br />
            We collect information from you when you register on our site, place
            an order, subscribe to updates, leave a message on Customer Service,
            or enter information on our site.
            <br />
            <br />
            Use and storage of your personal information
            <br />
            We may use the information we collect from you when you register,
            make a purchase, sign up for our newsletter, respond to a survey or
            marketing communication, surf the website, or use certain other site
            features in the following ways:
            <br />
            <br />
            To be able to contact you in the event of any problem with the
            delivery of your items
            <br />
            To process orders and to send information and updates pertaining to
            orders
            <br />
            To be able to send text message notifications of delivery status
            <br />
            To personalize your experience and to allow us to deliver the type
            of content and product offerings in which you are most interested
            <br />
            To allow us to better service you in responding to your customer
            service requests
            <br />
            To quickly process your transactions
            <br />
            To ask for ratings and reviews of services or products
            <br />
            <br />
            We will only keep your information for as long as necessary to carry
            out our services to you or for as long as we are required by law.
            After this your personal information will be deleted. Rest assured,
            your personal information is protected by an additional security
            layer called SSL which ensures your sensitive information is
            transmitted securely through an encrypted link.
          </p>
          <h2
            style={{ width: "100%" }}
            className="h2_main text-start pb-4 pt-4"
          >
            COOKIES
          </h2>
          <p>
            We also use non-personal data such as third-party cookies to collect
            statistics to enhance and simplify your visit. A cookie is a piece
            of information that is held on the hard drive of your computer which
            records how you have used a website. Cookies make it easier for you
            to log on and use the Website during future visits.
            <br />
            The permanent cookies are stored on your computer or mobile device
            for no longer than 24 months. You can easily erase cookies from your
            computer or mobile device using your browser.
          </p>
          <h2
            style={{ width: "100%" }}
            className="h2_main text-start pb-4 pt-4"
          >
            YOUR RIGHTS
          </h2>
          <p>
            You have the right to request information about the personal data we
            hold on you. If your data is incorrect, incomplete or irrelevant,
            you can ask to have the information corrected or removed.
          </p>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default PrivacyPolicyScreen;
