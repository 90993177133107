import axios from "axios"

   export  const  listProducts = ()=>{ return async(dispatch) => {

        try{
        dispatch({ type:'PRODUCT_LIST_REQUEST'})
        const {data}= await axios.get(`/api/products`)
        dispatch({type:'PRODUCT_LIST_SUCCESS',
        payload: data})
        } catch(error){
        dispatch({type: 'PRODUCT_LIST_FAIL',
        payload: error.response && error.response.data.message ? error.response.data.message : error.message})
        }
    }}
        
    export  const  listProductQuery = (keyword = ' ', collectionName = ' ', category = ' ', brand='none')=>{ return async(dispatch) => {

        try{
        dispatch({ type:'PRODUCT_QUERY_REQUEST'})

        const {data}= await axios.get(`/api/products/query?keyword=${keyword}&collectionName=${collectionName}&category=${category}&brand=${brand}`)
        dispatch({type:'PRODUCT_QUERY_SUCCESS',
        payload: data})
        } catch(error){
        dispatch({type: 'PRODUCT_QUERY_FAIL',
        payload: error.response && error.response.data.message ? error.response.data.message : error.message})
        }
    }}

    export  const  listProductDetails = (id)=>{ return async(dispatch) => {

        try{
        dispatch({ type:'PRODUCT_DETAILS_REQUEST'})
        const {data}= await axios.get(`/api/products/${id}`)
        dispatch({type:'PRODUCT_DETAILS_SUCCESS',
        payload: data})
        } catch(error){
        dispatch({type: 'PRODUCT_DETAILS_FAIL',
        payload: error.response && error.response.data.message ? error.response.data.message : error.message})
        }
    }}
        


export const deleteProduct = (id)=>{ return async(dispatch, getState) => {

    try {
        dispatch({
            type: 'PRODUCT_DELETE_REQUEST'
        })
        const {userLogin: {userInfo}}= getState()
        const config={
            headers: {
                Authorization:`Bearer ${userInfo.token}`
            }
        }
         await axios.delete(`/api/products/${id}`, config)

        dispatch({
            type: 'PRODUCT_DELETE_SUCCESS',
        })

    } catch (error) {
        dispatch({type: 'PRODUCT_DELETE_FAIL',
        payload: error.response && error.response.data.message ? error.response.data.message : error.message})
    }
}}




export const createProduct = ()=>{ return async(dispatch, getState) => {

    try {
        dispatch({
            type: 'PRODUCT_CREATE_REQUEST'
        })
        const {userLogin: {userInfo}}= getState()
        const config={
            headers: {
                Authorization:`Bearer ${userInfo.token}`
            }
        }
        const {data}= await axios.post(`/api/products`, {}, config)

        dispatch({
            type: 'PRODUCT_CREATE_SUCCESS',
            payload:data
        })

    } catch (error) {
        dispatch({type: 'PRODUCT_CREATE_FAIL',
        payload: error.response && error.response.data.message ? error.response.data.message : error.message})
    }
}}





export const updateProduct = (product)=>{ return async(dispatch, getState) => {

    try {
        dispatch({
            type: 'PRODUCT_UPDATE_REQUEST'
        })
        const {userLogin: {userInfo}}= getState()
        const config={
            'Content-Type': 'application/json',
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const {data}= await axios.put(`/api/products/${product._id}`, product, config)

        dispatch({
            type: 'PRODUCT_UPDATE_SUCCESS',
            payload:data
        })

    } catch (error) {
        dispatch({type: 'PRODUCT_UPDATE_FAIL',
        payload: error.response && error.response.data.message ? error.response.data.message : error.message})
    }
}}

