import React from "react";
import styles from "./CommonHero.module.css";
import { Col, Container, Row } from "react-bootstrap";

const CommonHero = ({ image, heading, text, span }) => {
  return (
    <div className={styles.Hero}>
      <div
        style={{ backgroundImage: `url(${image})` }}
        className={styles.Hero_Cont}
      >
        <Container>
          <Row>
            <Col lg={7}>
              <div className={styles.Hero_Content}>
                <h1 style={{ color: "white" }} className="heading_capital">
                  {heading} <span>{span}</span>
                </h1>
                <p className="para_main">{text}</p>
              </div>
            </Col>
            <Col lg={5}></Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default CommonHero;
