import React from "react";
import styles from "./IdvProduct.module.css";
import { Link } from "react-router-dom";

const IdvProduct = ({ product, productPage }) => {
  return (
    <div className={styles.cont}>
      <div className={styles.boxCont}>
        <Link
          style={{ width: "100%", height: "100%", textDecoration: "none" }}
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
          to={`/product/${product._id}`}
        >
          <img
            src={product.image}
            className={
              productPage
                ? `${styles.productPage} ${styles.box}`
                : `${styles.box}`
            }
          />
          <h5 className="h5_main">{product.name}</h5>
          <div className={styles.foot}>
            {product.sellPrice || product.sellPrice > 0 ? (
              <>
                <h3 className="para_main">${product.price}</h3>
                <h4 className="para_main">${product.sellPrice}</h4>
              </>
            ) : (
              <>
                <p className="para_main">${product.price}</p>
              </>
            )}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default IdvProduct;
