import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className={styles.main}>
      <footer className={styles.footer}>
        <Container>
          <Row className="gy-5">
            <Col lg={5}>
              <div className={styles.contentCol}>
                <img className={styles.logo} src="/images/logo.svg" />
                <h4 className="h4_main">About us</h4>
                <p className="para_main">
                  From 2017 Abqaree has provided Pakistani fashion in North
                  America. Being the official retailer for many of the companies
                  in all of North America gives us the opportunity to bring you
                  the top notch quality dresses with guarantee for originality,
                  with many variations of colours, patterns and fabric that can
                  really satisfy your day. The incredible cross stitching from
                  Qalamkar, the beautiful and unique colours from Asim Jofa, the
                  various and one of a kind designs from Maria.B, Afrozeh for
                  its pastel and spring use of colours and the carefully placed
                  pearl work in Gulaal’s dresses helps us achieve that broad
                  verity that we provide. Plus our very own designed dresses
                  with an extravagant thought process to fit your liking and
                  comfortability at the same time.
                </p>
                <div className={styles.iconCont}>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/abqarees_dresses_for_you/"
                  >
                    <img src="/images/instagram.svg" />
                  </a>
                  <a target="_blank" href="https://www.facebook.com/abqarees">
                    <img src="/images/facebook.svg" />
                  </a>
                </div>
              </div>
            </Col>
            <Col className="pt-lg-5" lg={3}>
              <div className={styles.listCol}>
                <div className={styles.list}>
                  <h4 className="h4_main">Quick Links</h4>
                  <Link
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                    to="/return-policy"
                  >
                    <h6>Returns & Exchange</h6>
                  </Link>
                  <Link
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                    to="/privacy-policy"
                  >
                    <h6>Privacy Policy</h6>
                  </Link>
                  <Link
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                    to="/contact"
                  >
                    <h6>Contact us</h6>
                  </Link>
                </div>
              </div>
            </Col>
            <Col className="pt-lg-5" lg={4}>
              <div className={styles.contactCol}>
                <h4 className="h4_main">Be the first to know!</h4>
                <p className="para_main">
                  By subscribing, you agree to receive marketing communications
                  from Abqaree. We promise to respect your privacy and never
                  spam you. You can unsubscribe at any time.
                </p>
                <form>
                  <div className={styles.input}>
                    <input
                      required
                      maxLength="32"
                      type="email"
                      placeholder="Type Your Email"
                    />
                    <button type="submit">Subscribe</button>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default Footer;
