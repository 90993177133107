import React from "react";
import styles from "./Reviews.module.css";
import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import "./Reviews.css";
import "swiper/css";
import "swiper/css/pagination";

const Reviews = () => {
  return (
    <div className={styles.main}>
      <div className={styles.boxCont}>
        <Container>
          <h2 className="h2_main">PRODUCT REVIEWS</h2>
          <a
            style={{ textDecoration: "none" }}
            target="no_blank"
            href="https://g.co/kgs/z6bGkR4"
          >
            <Swiper
              loop={true}
              slidesPerView={1}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              modules={[Autoplay, Pagination]}
              className="reviews_swiper"
            >
              <SwiperSlide
                style={{ background: "transparent" }}
                className={styles.box}
              >
                <img src="/images/reviews.svg" />
                <p className="para_main">
                  Had a great experience having my wedding dress made here. All
                  alterations were perfect and reasonably priced. In love with
                  the result!
                </p>
                <h4 className="h4_main">Sumayyah Ibrahim</h4>
              </SwiperSlide>

              <SwiperSlide
                style={{ background: "transparent" }}
                className={styles.box}
              >
                <img src="/images/reviews.svg" />
                <p className="para_main">
                  Thank you Aisha gor the great service. Love ur clothes and the
                  customised stitching is awesome. Have loved to wear clothes
                  from ur store. No complains the stitching is immaculate
                </p>
                <h4 className="h4_main">Asfiya Ali</h4>
              </SwiperSlide>
              <SwiperSlide
                style={{ background: "transparent" }}
                className={styles.box}
              >
                <img src="/images/reviews.svg" />
                <p className="para_main">
                  30 years later I had my original very white long mermaid
                  wedding dress redone into something I could wear today. It
                  turned out fabulous!!! Received SO many compliments … even
                  from random strangers. Felt as beautiful and excited as I did
                  30 years ago. Thank you Abqarees Dresses. You surpassed my
                  expectations.
                </p>
                <h4 className="h4_main">Marcelle Sears</h4>
              </SwiperSlide>
            </Swiper>
          </a>
        </Container>
      </div>
    </div>
  );
};

export default Reviews;
