import React, {Fragment, useEffect, useState} from 'react'
import{ Button, Container, Form} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import {getUserDetails, updateUser} from '../../actions/userActions'
import FormContainer from '../../components/FormContainer'
import Footer from '../../components/Footer_/Footer'
import Header from '../../components/Header_/Header'


const UserEditScreen = () => {

    const params= useParams()
    const userId= params.id

    const [firstName, setFirstName]= useState('')
    const [lastName, setLastName]= useState('')
    const [email, setEmail]= useState('')
    const [isAdmin, setIsAdmin]= useState(false)
   
    const userDetails = useSelector((state)=> state.userDetails) 
    const { lastName: userLastName, firstName: userFirstName, email: userEmail, isAdmin: userIsAdmin, _id, error, loading}= userDetails

    const userUpdate = useSelector((state)=> state.userUpdate) 
    const { error: errorUpdate, loading:loadingUpdate, success: successUpdate }= userUpdate

    const dispatch = useDispatch()
    const history = useNavigate()

    const userLogin = useSelector((state)=>state.userLogin)
    const{userInfo}= userLogin


useEffect(()=>{

if(!userInfo || !userInfo.isAdmin){
  history('/login')
  }

    if(successUpdate){
        dispatch({type: 'USER_UPDATE_RESET'})
        history('/admin/userlist')
    } else{

        if(!userFirstName || _id !== userId){
            dispatch(getUserDetails(userId))
             } else{
              setFirstName(userFirstName)
              setLastName(userLastName)
              setEmail(userEmail)
              setIsAdmin(userIsAdmin)
             }
     }
}
,[dispatch, history, _id, userId, userFirstName, userEmail, userIsAdmin, successUpdate, userInfo])



const formSubmitHander=(event)=>{
    event.preventDefault()
   dispatch(updateUser({_id: userId, firstName, lastName, email, isAdmin}))
}


  return (
    <>
    <Header/>
    <Fragment>
      <Container className='admin-screens-mg-top'>
        <Link to='/admin/userlist' className='btn btn-dark my-3'>Go Back</Link>
<FormContainer>
       <h1>Edit User</h1>
       {loadingUpdate && <Loader/>}
       {errorUpdate && <Message variant='danger'>{error}</Message>}
       {loading ? <Loader/> : error ? <Message variant='danger'>{error}</Message>
       : (
<Form onSubmit={formSubmitHander}>

  <Form.Group controlId='name'>
        <Form.Label>First Name</Form.Label>
        <Form.Control type='name' placeholder='Enter First Name'  value={firstName}
         onChange={(event)=>setFirstName(event.target.value)}></Form.Control>
      </Form.Group>

      <Form.Group controlId='name'>
        <Form.Label>Last Name</Form.Label>
        <Form.Control  type='name' placeholder='Enter Last Name'  value={lastName}
         onChange={(event)=>setLastName(event.target.value)}></Form.Control>
      </Form.Group>


  <Form.Group controlId='email'>
    <Form.Label>Email Address</Form.Label>
    <Form.Control type='email' placeholder='Enter Email' value={email}
     onChange={(event)=>setEmail(event.target.value)}></Form.Control>
  </Form.Group>

  <Form.Group controlId='isadmin'>
    <Form.Check type='checkbox' label='Is Admin' checked={isAdmin}
     onChange={(event)=>setIsAdmin(event.target.checked)}></Form.Check>
  </Form.Group>


  <br></br>
  <Button type='submit' variant='primary'>Update</Button>
</Form>)
}

</FormContainer>
</Container>
</Fragment>
<Footer/>
</>
  )
}

export default UserEditScreen