import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, ListGroup, Card, Image, Container } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CheckoutSteps from "../../components/ProgressBar/CheckoutSteps";
import { removeCartItems } from "../../actions/cartActions";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer_/Footer";
import Header from "../../components/Header_/Header";
import { PayPalButton } from "react-paypal-button-v2";
import axios from "axios";

const PlaceOrderScreen = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;
  const { paymentMethod } = cart;
  const { cartItems } = cart;
  const history = useNavigate();

  const [error, setError] = useState(null);
  const orderCreate = useSelector((state) => state.orderCreate);
  const { loading } = orderCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // Calculation

  cart.itemsPrice = cartItems
    .map((idv) => idv.price * idv.quantity)
    .reduce(priceSum, 0)
    .toFixed(2);
  function priceSum(total, num) {
    return total + num;
  }

  // Shipping

  let shippingPrice = 0;

  cartItems.forEach((item, index) => {

    const itemShippingPrice = item.shippingPrice;
    const quantity = item.quantity;

    if (index === 0) {
      shippingPrice += itemShippingPrice;
      if (quantity > 1) {
        shippingPrice += (quantity - 1) * (itemShippingPrice * 0.5);
      }
    } else {
      shippingPrice += quantity * (itemShippingPrice * 0.5);
    }
  });

  cart.shippingPrice = shippingPrice.toFixed(2);

  // Shipping end

  cart.taxPrice = 0;

  cart.totalPrice = (
    Number(cart.itemsPrice) +
    Number(cart.shippingPrice) +
    Number(cart.taxPrice)
  ).toFixed(2);

  useEffect(() => {
    if (!paymentMethod || cartItems.length === 0) {
      history("/payment");
    }
  }, [history, dispatch, paymentMethod, cartItems]);

  const OrderItems = cartItems.map((item) => {
    return {
      quantity: item.quantity,
      product: item.product,
      price: item.price,
      size: item.size,
      uniqueId: item.uniqueId,
    };
  });

  const createOrder = async (data, actions) => {
    try {
      const response = await axios.post("/api/orders/payment/create-order", {
        amount: cart.totalPrice,
      });

      const order = response.data;

      if (!order.orderID) {
        throw new Error("Order ID is missing");
      }
      return order.orderID;
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  const onApprove = async (data, actions) => {
    try {
      const orderID = data.orderID;

      const orderData = {
        orderID,
        orderItems: OrderItems,
        createdAt: Date().substring(0, 24),
        shippingAddress: shippingAddress,
        paymentMethod: paymentMethod,
        itemsPrice: cart.itemsPrice,
        shippingPrice: cart.shippingPrice,
        taxPrice: cart.taxPrice,
        totalPrice: cart.totalPrice,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await axios.post(
        "/api/orders/payment/capture-order",
        orderData,
        config
      );

      if (response.status === 200) {
        dispatch(removeCartItems());
        history(`/order/${response.data._id}`);
        window.location.reload(false);
      } else {
        console.error("Error creating order:", response.data);
        setError(response.data);
      }
    } catch (error) {
      console.error("Error capturing order:", error);
    }
  };

  return (
    <>
      <Header />
      <Fragment>
        <Container className="orderingScreens orderingScreensNoMargin">
          <div className="main">
            <CheckoutSteps classname="onPlaceOrder" />
            <Row>
              <Col md={8}>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <h2>Shipping</h2>
                    <Row>
                      <Col>
                        {" "}
                        <h6>Address</h6>{" "}
                        <p>
                          {" "}
                          {shippingAddress.address}, {shippingAddress.province},{" "}
                          {shippingAddress.city} {""}
                          {shippingAddress.postalCode}, {""}
                          {shippingAddress.country}
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <h6>Phone</h6> <p>{shippingAddress.phoneNum}</p>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <h2>Payment Method</h2>
                    <h6>Method</h6>
                    <p>{paymentMethod}</p>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <h2>Order Items</h2>
                    {cartItems.length === 0 ? (
                      <h5>
                        Your Cart is empty!{" "}
                        <Link className="" to="/shop">
                          Shop Now
                        </Link>
                      </h5>
                    ) : (
                      <ListGroup variant="flush">
                        {cartItems.map((item, index) => (
                          <ListGroup.Item key={index}>
                            <Row>
                              <Col className="mb-3" md={2}>
                                <Image
                                  src={item.image}
                                  alt={item.name}
                                  fluid
                                  rounded
                                ></Image>
                              </Col>
                              <Col>
                                <h5>{item.name}</h5>
                                <h6 style={{ margin: "0px" }}>Size:</h6>{" "}
                                <p>{item.size}</p>
                              </Col>
                              <Col md={4}>
                                <h6>Quantity:</h6> <p>{item.quantity}</p>
                                <h6>Product Price:</h6> <p>{item.price}</p>
                                <h6>Total Amount:</h6>{" "}
                                <p>
                                  {item.quantity} x {item.price} = $
                                  {Number(item.quantity * item.price).toFixed(
                                    2
                                  )}
                                </p>
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    )}
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col md={4}>
                <Card>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <h2>Order Summary</h2>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <h5>Items</h5>
                        </Col>
                        <Col>
                          <p>${cart.itemsPrice}</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <h5>Shipping</h5>
                        </Col>
                        <Col>
                          <p>${cart.shippingPrice}</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <h5>Tax</h5>
                        </Col>
                        <Col>
                          <p>${cart.taxPrice}</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <h5>Total Price</h5>
                        </Col>
                        <Col>
                          <p>${cart.totalPrice}</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    {error && <ListGroup.Item>{error}</ListGroup.Item>}
                    {loading ? (
                      <Loader />
                    ) : (
                      <ListGroup.Item>
                        <PayPalButton
                          currency={"CAD"}
                          options={{
                            clientId:
                              "AXa7VVbTE4u9EObtPQNobVYnTtiWWNvycOhbwALyD53NMn5024dOfVTFQMYXErkr9P2xd7BpfFcIBlIh",
                            currency: "CAD",
                          }}
                          shippingPreference="NO_SHIPPING"
                          createOrder={createOrder}
                          onApprove={onApprove}
                        />
                      </ListGroup.Item>
                    )}
                  </ListGroup>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </Fragment>
      <Footer />
    </>
  );
};

export default PlaceOrderScreen;
