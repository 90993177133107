import React, { Fragment } from 'react'
import { Nav, Container } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import './CheckoutSteps.css'

// {step1, step2, step3, step4}

const CheckoutSteps = (props) => {
  return (
    <Fragment>


    <Nav className='justify-content-center mb-4'>
    <Container className='text-center' fluid>
 
 <ol id="progress-bar" className='text-center'>
   <li className="step-done pg-bar-link"><Nav.Link className='pg-bar-link'>Sign In</Nav.Link></li> 
   <li className={props.classname==='onShipping' ? 'step-active': props.classname==='onPayment' ? 'step-done': props.classname==='onPlaceOrder' ? 'step-done': ''}><LinkContainer to='/shipping'><Nav.Link className='pg-bar-link'>Shipping</Nav.Link></LinkContainer></li>
   <li className={props.classname==='onPayment' ? 'step-active': props.classname==='onPlaceOrder' ? 'step-done': props.classname==='onPlaceOrder' ? 'step-done': ''}><LinkContainer  to='/payment'><Nav.Link className='pg-bar-link'>Payment Method</Nav.Link></LinkContainer></li>
   <li className={props.classname==='onPlaceOrder' && 'step-active'}><Nav.Link className='pg-bar-link'>Place Order</Nav.Link></li>
 </ol>
 
 </Container>
    </Nav>
    </Fragment>
    )
}

export default CheckoutSteps