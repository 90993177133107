import React, { Fragment } from "react";
import Footer from "../../components/Footer_/Footer";
import Header from "../../components/Header_/Header";
import MainCategoriesSec from "../../components/MainCategoriesSec_/MainCategoriesSec";
import BlogSec from "../../components/BlogSec_/BlogSec";
import CategoriesSec from "../../components/CategoriesSec_/CategoriesSec";
import HomeHero from "../../components/HomeHero_/HomeHero";
import Reviews from "../../components/Reviews_/Reviews";
import CategoriesRow from "../../components/CategoriesRow_/CategoriesRow";

const HomeScreen = () => {
  return (
    <>
      <div style={{ background: "rgb(45, 45, 45)", padding:"10px 0px 10px 0px" }}>
        <p style={{ color: "white" }} className="para_main">
          SHIPPING IS ONLY AVAILABLE IN CANADA AND US
        </p>
      </div>
      <Header />
      <HomeHero />
      <CategoriesRow heading="New Arrival" />
      <MainCategoriesSec />
      <BlogSec />
      <Reviews />
      <Footer />
    </>
  );
};

export default HomeScreen;
