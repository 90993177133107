import React, { useState } from "react";
import { Col, Container, Nav, NavDropdown, Navbar, Row } from "react-bootstrap";
import styles from "./Header.module.css";
import { LinkContainer } from "react-router-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/userActions";

const Header = () => {
  const [show, setShow] = useState(false);
  const [category, setCategory] = useState(null);
  const [brand, setBrand] = useState(null);
  const [query, setQuery] = useState(undefined);
  const [showBars, setShowBars] = useState(true);
  const [showCross, setShowCross] = useState(false);
  const [mobSearch, setMobSearch] = useState(false);

  const history = useNavigate();

  const mouseEnterHandler = (val) => {
    setShow(true);
    setCategory(val);
    setBrand(null);
    // document.body.style.overflow = "hidden";
  };
  const brandMouseEnterHandler = () => {
    setShow(true);
    setBrand(true);
    // document.body.style.overflow = "hidden";
  };
  const mouseLeaveHandler = () => {
    setShow(false);
    setBrand(null);
    // document.body.style.overflow = "auto";
  };

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const productQty = cartItems.map((idv) => idv.quantity);
  const ttlQty = productQty.reduce(qtySum, 0);
  function qtySum(total, num) {
    return total + Math.round(num);
  }

  const logoutHandler = () => {
    dispatch(logout());
    window.location.reload(false);
  };

  const signInHandler = () => {
    localStorage.removeItem("googleUserData");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const toggleHandler = () => {
    if (showCross) {
      setShowCross(false);
      setShowBars(true);
    }
  };
  const barsClickHandler = () => {
    setShowCross(true);
    setShowBars(false);
  };
  const crossClickHandler = () => {
    setShowCross(false);
    setShowBars(true);
  };

  const querySubmitHandler = (e) => {
    e.preventDefault();
    setMobSearch(false);
    if (query.trim()) {
      history(`/shop/search/${query}`);
    } else {
      history("/");
    }
  };

  return (
    <>
      <header className={styles.headerDesktop}>
        <Container>
          <div className={styles.row}>
            <div className={styles.logoCont}>
              <Link to="/home">
                <img src="/images/logo.svg" />{" "}
              </Link>
            </div>
            <div className={styles.contentCont}>
              <div className="pb-3">
                <img
                  className={styles.contentImg}
                  src="/images/abqaree_header.png"
                />
              </div>
              <div className={styles.contentRow}>
                <div className={styles.listCont}>
                  <NavLink
                    onMouseEnter={mouseLeaveHandler}
                    to="/home"
                    className={({ isActive }) =>
                      isActive ? styles.activeLink : styles.link
                    }
                  >
                    Home
                  </NavLink>

                  <NavLink
                    onMouseEnter={mouseLeaveHandler}
                    to="/shop"
                    className={({ isActive }) =>
                      isActive ? styles.activeLink : styles.link
                    }
                  >
                    Shop
                  </NavLink>

                  <div className={styles.dropdown}>
                    <NavLink
                      onMouseEnter={mouseLeaveHandler}
                      to="/category/all"
                      className={({ isActive }) =>
                        isActive ? styles.activeLink : styles.link
                      }
                    >
                      Shop By Category
                    </NavLink>
                    <div className={styles.dropdownContentCont}>
                      <div className={styles.dropdownContent}>
                        <NavLink
                          onMouseEnter={mouseLeaveHandler}
                          to="/category/shirt-&-trouser"
                          className={({ isActive }) =>
                            isActive
                              ? `${styles.dropdownContentActiveLink} ${styles.dropdownContentLink}`
                              : styles.dropdownContentLink
                          }
                        >
                          <h6 className="h6_main pb-2">Shirt & Trouser</h6>
                        </NavLink>
                        <NavLink
                          onMouseEnter={mouseLeaveHandler}
                          to="/category/maxi"
                          className={({ isActive }) =>
                            isActive
                              ? `${styles.dropdownContentActiveLink} ${styles.dropdownContentLink}`
                              : styles.dropdownContentLink
                          }
                        >
                          <h6 className="h6_main pb-2">Maxi</h6>
                        </NavLink>
                        <NavLink
                          onMouseEnter={mouseLeaveHandler}
                          to="/category/lehnga"
                          className={({ isActive }) =>
                            isActive
                              ? `${styles.dropdownContentActiveLink} ${styles.dropdownContentLink}`
                              : styles.dropdownContentLink
                          }
                        >
                          <h6 className="h6_main pb-2">Lehnga</h6>
                        </NavLink>
                        <NavLink
                          onMouseEnter={mouseLeaveHandler}
                          to="/category/gharara"
                          className={({ isActive }) =>
                            isActive
                              ? `${styles.dropdownContentActiveLink} ${styles.dropdownContentLink}`
                              : styles.dropdownContentLink
                          }
                        >
                          <h6 className="h6_main pb-2">Gharara</h6>
                        </NavLink>
                        <NavLink
                          onMouseEnter={mouseLeaveHandler}
                          to="/category/sharara"
                          className={({ isActive }) =>
                            isActive
                              ? `${styles.dropdownContentActiveLink} ${styles.dropdownContentLink}`
                              : styles.dropdownContentLink
                          }
                        >
                          <h6 className="h6_main pb-2">Sharara</h6>
                        </NavLink>
                      </div>
                    </div>
                  </div>

                  <NavLink
                    onMouseEnter={mouseLeaveHandler}
                    to="/contact"
                    className={({ isActive }) =>
                      isActive ? styles.activeLink : styles.link
                    }
                  >
                    Contact
                  </NavLink>
                </div>
                <div
                  onMouseEnter={mouseLeaveHandler}
                  className={styles.infoCont}
                >
                  <div className={styles.search}>
                    <form
                      onSubmit={querySubmitHandler}
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>
                        <img src="/images/search.svg" />
                      </span>
                      <input
                        maxLength="22"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        type="text"
                        placeholder="Search"
                      />
                    </form>
                  </div>
                  <NavLink to="/cart" className={styles.icon}>
                    <img src="/images/bag.svg" />({`${ttlQty}`})
                  </NavLink>
                  {userInfo ? (
                    <NavDropdown
                      title={userInfo.firstName}
                      className={styles.username}
                    >
                      {userInfo && userInfo.isAdmin && (
                        <>
                          <LinkContainer
                            activeClassName=""
                            to="/admin/userlist"
                          >
                            <NavDropdown.Item>Users</NavDropdown.Item>
                          </LinkContainer>

                          <LinkContainer
                            activeClassName=""
                            to="/admin/productlist"
                          >
                            <NavDropdown.Item>Products</NavDropdown.Item>
                          </LinkContainer>

                          <LinkContainer
                            activeClassName=""
                            to="/admin/orderlist"
                          >
                            <NavDropdown.Item>Orders</NavDropdown.Item>
                          </LinkContainer>
                        </>
                      )}
                      <LinkContainer activeClassName="" to="/profile">
                        <NavDropdown.Item>Profile</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer activeClassName="" to="/myorders">
                        <NavDropdown.Item>My Orders</NavDropdown.Item>
                      </LinkContainer>

                      <NavDropdown.Item onClick={logoutHandler}>
                        Logout
                      </NavDropdown.Item>
                    </NavDropdown>
                  ) : (
                    <NavLink
                      className={styles.icon}
                      to="/login"
                      onClick={signInHandler}
                    >
                      <img src="/images/user.svg"></img> Sign In
                    </NavLink>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </header>

      {/* MOBILE NAVIGATION */}

      {mobSearch && (
        <header className={styles.mobSearch}>
          <div className={styles.searchCont}>
            <div style={{ marginRight: "12px" }} className={styles.search}>
              <form
                onSubmit={querySubmitHandler}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span>
                  <img src="/images/search.svg" />
                </span>
                <input
                  maxLength="22"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  type="text"
                  placeholder="Search Products"
                />
              </form>
            </div>
            <img
              onClick={() => setMobSearch(false)}
              className={styles.close}
              src="/images/close.svg"
            />
          </div>
        </header>
      )}

      {!mobSearch && (
        <header className={styles.mobHeader}>
          <Navbar
            className={styles.mobNav}
            expand="lg"
            collapseOnSelect
            onToggle={toggleHandler}
          >
            <Container>
              <div className={styles.logoCont}>
                <Link to="/home">
                  <img src="/images/logo.svg" />{" "}
                </Link>
              </div>

              <Navbar className="ms-auto">
                {userInfo ? (
                  <NavDropdown
                    className={styles.username}
                    title={userInfo.firstName}
                  >
                    {userInfo && userInfo.isAdmin && (
                      <>
                        <LinkContainer
                          activeClassName=""
                          style={{ paddingLeft: "12px", paddingTop: "6px" }}
                          to="/admin/userlist"
                        >
                          <NavDropdown.Item>Users</NavDropdown.Item>
                        </LinkContainer>

                        <LinkContainer
                          activeClassName=""
                          style={{ paddingLeft: "12px", paddingTop: "6px" }}
                          to="/admin/productlist"
                        >
                          <NavDropdown.Item>Products</NavDropdown.Item>
                        </LinkContainer>

                        <LinkContainer
                          activeClassName=""
                          style={{ paddingLeft: "12px", paddingTop: "6px" }}
                          to="/admin/orderlist"
                        >
                          <NavDropdown.Item>Orders</NavDropdown.Item>
                        </LinkContainer>
                      </>
                    )}
                    <LinkContainer
                      activeClassName=""
                      style={{ paddingLeft: "12px", paddingTop: "6px" }}
                      to="/profile"
                    >
                      <NavDropdown.Item>Profile</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer
                      activeClassName=""
                      style={{ paddingLeft: "12px", paddingTop: "6px" }}
                      to="/myorders"
                    >
                      <NavDropdown.Item>My Orders</NavDropdown.Item>
                    </LinkContainer>
                    <NavDropdown.Item
                      style={{ paddingLeft: "12px", paddingTop: "6px" }}
                      onClick={logoutHandler}
                    >
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                ) : (
                  <NavLink
                    className={styles.mobIcon}
                    to="/login"
                    onClick={signInHandler}
                  >
                    <img
                      style={{ marginRight: "12px" }}
                      src="/images/user.svg"
                    ></img>
                  </NavLink>
                )}

                <span
                  style={{
                    marginRight: "12px",
                    width: "15px",
                    height: "15px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => setMobSearch(true)}
                >
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src="/images/search.svg"
                  />
                </span>

                <NavLink to="/cart" className={styles.mobIcon}>
                  <img src="/images/bag.svg" />({`${ttlQty}`})
                </NavLink>
              </Navbar>

              {showBars && (
                <Navbar.Toggle
                  as="button"
                  className={styles.mobToggler}
                  onClick={barsClickHandler}
                  aria-controls="basic-navbar-nav"
                >
                  <i className="fa-solid fa-bars"></i>
                </Navbar.Toggle>
              )}
              {showCross && (
                <Navbar.Toggle
                  as="button"
                  className={styles.mobToggler}
                  onClick={crossClickHandler}
                  aria-controls="basic-navbar-nav"
                >
                  <i className="fa-solid fa-xmark"></i>
                </Navbar.Toggle>
              )}
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav
                  style={{ paddingTop: "20px", gap: "7px" }}
                  className={`${styles.mobNav} ms-auto`}
                >
                  <NavLink
                    to="/home"
                    className={({ isActive }) =>
                      isActive ? styles.activeLink : styles.link
                    }
                  >
                    Home
                  </NavLink>
                  <NavLink
                    to="/shop"
                    className={({ isActive }) =>
                      isActive ? styles.activeLink : styles.link
                    }
                  >
                    Shop
                  </NavLink>
                  <NavLink
                    to="/category/all"
                    className={({ isActive }) =>
                      isActive ? styles.activeLink : styles.link
                    }
                  >
                    Shop By Category
                  </NavLink>
                  <NavLink
                    to="/category/shirt-&-trouser"
                    className={({ isActive }) =>
                      isActive ? styles.activeLink : styles.link
                    }
                  >
                    Shirt & Trouser
                  </NavLink>
                  <NavLink
                    to="/category/maxi"
                    className={({ isActive }) =>
                      isActive ? styles.activeLink : styles.link
                    }
                  >
                    Maxi
                  </NavLink>
                  <NavLink
                    to="/category/lehnga"
                    className={({ isActive }) =>
                      isActive ? styles.activeLink : styles.link
                    }
                  >
                    Lehnga
                  </NavLink>
                  <NavLink
                    to="/category/gharara"
                    className={({ isActive }) =>
                      isActive ? styles.activeLink : styles.link
                    }
                  >
                    Gharara
                  </NavLink>
                  <NavLink
                    to="/category/sharara"
                    className={({ isActive }) =>
                      isActive ? styles.activeLink : styles.link
                    }
                  >
                    Sharara
                  </NavLink>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </header>
      )}
    </>
  );
};

export default Header;
