import React from "react";
import styles from "./HomeHero.module.css";
import "./HomeHero.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";

const HomeHero = () => {
  return (
    <div className={styles.main}>
      <Carousel interval={2300} className="box">
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/images/hero1.jpg"
            alt="First slide"
          />
          {/* <Carousel.Caption>
            <h1 style={{ color: "white" }} className="heading_capital">
              New <span>Arrival</span>
            </h1>
            <h4 className="h4_main">RUN WITH THE PLANET IN MIND</h4>
            <Link onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }} to="/shop">
              <button className="button_main button_white">
                <span>Shop Now</span>
              </button>
            </Link>
          </Carousel.Caption> */}
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/images/hero2.jpg"
            alt="Second slide"
          />
          {/* <Carousel.Caption>
            <h1 style={{ color: "white" }} className="heading_capital">
              <span>Best</span> Sellers
            </h1>
            <h4
              style={{ letterSpacing: "5px", color: "white" }}
              className="h4_main"
            >
              RUN WITH THE PLANET IN MIND
            </h4>
            <Link onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }} to="/shop">
              <button className="button_main button_white">
                <span>Shop Now</span>
              </button>
            </Link>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/images/hero3.jpg"
            alt="Third slide"
          />
          {/* <Carousel.Caption>
            <h1 style={{ color: "white" }} className="heading_capital">
              New <span>Arrival</span>
            </h1>
            <h4
              style={{ letterSpacing: "5px", color: "white" }}
              className="h4_main"
            >
              RUN WITH THE PLANET IN MIND
            </h4>
            <Link onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }} to="/shop">
              <button className="button_main button_white">
                <span>Shop Now</span>
              </button>
            </Link>
          </Carousel.Caption> */}
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default HomeHero;
