import React, { useEffect, useRef } from "react";
import Footer from "../../components/Footer_/Footer";
import Header from "../../components/Header_/Header";
import CommonHero from "../../components/CommonHero_/CommonHero";
import DynamicProducts from "../../components/DynamicProducts/DynamicProducts";
import CategoriesRow from "../../components/CategoriesRow_/CategoriesRow";
import { useParams } from "react-router-dom";

const ShopScreen = () => {
  const myRef = useRef(null);
  const params = useParams();
  const query = params.keyword;

  const executeScroll = () => myRef.current.scrollIntoView();

  useEffect(() => {
    if (query) {
      executeScroll();
    }
  }, [query]);

  return (
    <>
      <Header />
      <CommonHero
        image="/images/shop-hero.jpg"
        heading="Unstitched Collection"
        text="Craft your unique winter look with our Unstitched Winter'23 Collection where style knows no limits.Craft your unique winter look with our Unstitched Winter'23 Collection where style knows no limits.Craft your unique winter look with our Unstitched Winter'23 Collection where style knows no limits."
      />
      <DynamicProducts Ref={myRef} />
      <CategoriesRow heading="Recommended Products" swiper={true} />
      <Footer />
    </>
  );
};

export default ShopScreen;
