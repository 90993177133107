import React, { useState, useEffect } from "react";
import { Row, Col, Button, Image, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listProductDetails } from "../../actions/productActions";
import Message from "../../components/Message";
import { addToCartAnimate } from "../../actions/cartActions";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs, Zoom } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/zoom";
import styles from "./ProductScreen.module.css";
import Footer from "../../components/Footer_/Footer";
import Header from "../../components/Header_/Header";
import CategoriesRow from "../../components/CategoriesRow_/CategoriesRow";
import Reviews from "../../components/Reviews_/Reviews";

const ProductScreen = () => {
  const [quantity, setQuantity] = useState(1);
  const [size, setSize] = useState(null);

  const productDetails = useSelector((state) => state.productDetails);
  const { error, product } = productDetails;
  const dispatch = useDispatch();
  const params = useParams();
  const history = useNavigate();

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  useEffect(() => {
    dispatch({ type: "PRODUCT_DETAILS_RESET" });
    dispatch(listProductDetails(params.id));
  }, [params, dispatch]);

  const addToCartHandler = () => {
    dispatch({ type: "ADD_ITEM_ANIMATE_RESET" });
    dispatch(addToCartAnimate());
    history(`/cart/${params.id}?quantity=${quantity}&&size=${size}`);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <>
      <Header />

      <div className="main">
        <Container>
          {error ? (
            <Message variant="danger">{error}</Message>
          ) : (
            <Row className="gy-4">
              <Col lg={6}>
                {product.image !== "none" && (
                  <>
                    <Swiper
                      spaceBetween={0}
                      zoom={true}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[FreeMode, Thumbs, Zoom]}
                      className={styles.swiper2}
                    >
                      {product.image !== "none" && (
                        <SwiperSlide className={styles.SwiperSlide2}>
                          <div className="swiper-zoom-container">
                            <Image src={product.image} fluid />
                          </div>
                        </SwiperSlide>
                      )}
                      {product.image2 !== "none" && (
                        <SwiperSlide className={styles.SwiperSlide2}>
                          <div className="swiper-zoom-container">
                            <Image src={product.image2} fluid />
                          </div>
                        </SwiperSlide>
                      )}
                      {product.image3 !== "none" && (
                        <SwiperSlide className={styles.SwiperSlide2}>
                          <div className="swiper-zoom-container">
                            <Image src={product.image3} fluid />
                          </div>
                        </SwiperSlide>
                      )}
                      {product.image4 !== "none" && (
                        <SwiperSlide className={styles.SwiperSlide2}>
                          <div className="swiper-zoom-container">
                            <Image src={product.image4} fluid />
                          </div>
                        </SwiperSlide>
                      )}
                      {product.image5 !== "none" && (
                        <SwiperSlide className={styles.SwiperSlide2}>
                          <div className="swiper-zoom-container">
                            <Image src={product.image5} fluid />
                          </div>
                        </SwiperSlide>
                      )}
                      {product.image6 !== "none" && (
                        <SwiperSlide className={styles.SwiperSlide2}>
                          <div className="swiper-zoom-container">
                            <Image src={product.image6} fluid />
                          </div>
                        </SwiperSlide>
                      )}
                    </Swiper>
                    <Swiper
                      onSwiper={setThumbsSwiper}
                      spaceBetween={10}
                      slidesPerView={5}
                      freeMode={true}
                      watchSlidesProgress={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className={styles.swiper}
                    >
                      {product.image !== "none" && (
                        <SwiperSlide>
                          <Image src={product.image} fluid />
                        </SwiperSlide>
                      )}
                      {product.image2 !== "none" && (
                        <SwiperSlide>
                          <Image src={product.image2} fluid />
                        </SwiperSlide>
                      )}
                      {product.image3 !== "none" && (
                        <SwiperSlide>
                          <Image src={product.image3} fluid />
                        </SwiperSlide>
                      )}
                      {product.image4 !== "none" && (
                        <SwiperSlide>
                          <Image src={product.image4} fluid />
                        </SwiperSlide>
                      )}
                      {product.image5 !== "none" && (
                        <SwiperSlide>
                          <Image src={product.image5} fluid />
                        </SwiperSlide>
                      )}
                      {product.image6 !== "none" && (
                        <SwiperSlide>
                          <Image src={product.image6} fluid />
                        </SwiperSlide>
                      )}
                    </Swiper>
                  </>
                )}
              </Col>
              <Col lg={6}>
                <div className={styles.box}>
                  <div className={styles.headingCont}>
                    <h2 className="h2_main">{product.name}</h2>
                    <div className={styles.priceRow}>
                        {product.sellPrice || product.sellPrice > 0 ? (
                          <>
                            <h3 className="h5_main">${product.price}.00</h3>
                            <h5 className="h5_main">${product.sellPrice}.00</h5>
                          </>
                        ) : (
                          <>
                            <h5 className="h5_main">${product.price}.00</h5>
                          </>
                        )}
                      </div>
                  </div>
                  <div className={styles.customCont}>
                    <div className={styles.custom1}>
                      <h5 className="h5_main">Availability:</h5>
                      <h5 className="h5_main">
                        Size:{" "}
                        <span
                          className="para_main"
                          style={{
                            color: "red",
                            fontSize: "13px",
                            fontWeight: "900",
                          }}
                        >
                          Select*
                        </span>
                      </h5>
                      <h5 className="h5_main">Custom Order:</h5>
                    </div>

                    <div className={styles.custom2}>
                      <h4 className="h4_main">
                        {product.countInStock > 0
                          ? `${product.countInStock} In Stock`
                          : "Out Of Stock"}
                      </h4>
                      <div className={styles.sizes}>
                        {/* {product.size1 !== "none" && (
                          <span
                            onClick={() => setSize("XS")}
                            className={size === "XS" && styles.sizeActive}
                          >
                            XS
                          </span>
                        )} */}
                        {product.size2 !== "none" && (
                          <span
                            onClick={() => setSize("S")}
                            className={size === "S" && styles.sizeActive}
                          >
                            S
                          </span>
                        )}
                        {product.size3 !== "none" && (
                          <span
                            onClick={() => setSize("M")}
                            className={size === "M" && styles.sizeActive}
                          >
                            M
                          </span>
                        )}
                        {product.size4 !== "none" && (
                          <span
                            onClick={() => setSize("L")}
                            className={size === "L" && styles.sizeActive}
                          >
                            L
                          </span>
                        )}
                        {product.size5 !== "none" && (
                          <span
                            onClick={() => setSize("XL")}
                            className={size === "XL" && styles.sizeActive}
                          >
                            XL
                          </span>
                        )}
                        {product.size1 === "none" &&
                          product.size2 === "none" &&
                          product.size3 === "none" &&
                          product.size4 === "none" &&
                          product.size5 === "none" && (
                            <>
                              <span>None</span>
                            </>
                          )}
                      </div>
                      <a
                        style={{ width: "128px", textDecoration: "none" }}
                        target="no_blank"
                        href="https://wa.me/message/U3RCDSXDN5QAB1"
                      >
                        <Button
                          style={{
                            width: "100%",
                            textTransform: "none",
                            borderRadius: "6px",
                          }}
                          className={styles.addToCart}
                          type="button"
                        >
                          Submit Here
                        </Button>
                      </a>
                    </div>
                  </div>
                  <div className={styles.buttonCont}>
                    {product.countInStock > 0 && (
                      <div className={styles.input}>
                        <img
                          onClick={() =>
                            quantity < product.countInStock &&
                            setQuantity(quantity + 1)
                          }
                          src="/images/plus_icon.svg"
                        />
                        <span>{quantity}</span>
                        <img
                          onClick={() =>
                            quantity > 1 && setQuantity(quantity - 1)
                          }
                          src="/images/minus_icon.svg"
                        />
                      </div>
                    )}
                    <Button
                      className={styles.addToCart}
                      type="button"
                      disabled={
                        product.countInStock === 0 ||
                        (product.size1 === "none" &&
                          product.size2 === "none" &&
                          product.size3 === "none" &&
                          product.size4 === "none" &&
                          product.size5 === "none") ||
                        !size
                      }
                      onClick={addToCartHandler}
                    >
                      Add To Cart
                    </Button>
                  </div>

                  <div className={styles.infoCont}>
                    <h5 className="h5_main">Description:</h5>
                    <div
                      style={{ textAlign: "start" }}
                      dangerouslySetInnerHTML={{ __html: product.description }}
                      className="para_main"
                    />
                  </div>
                </div>
              </Col>
              {product.sizeChart && (
                <Col lg={6}>
                  <h5 className="h5_main pb-3">Size Chart:</h5>
                  <img className={styles.sizeChart} src={product.sizeChart} />
                </Col>
              )}
            </Row>
          )}
        </Container>
      </div>

      <CategoriesRow heading="Recommended Products" swiper={true} />
      <Reviews />
      <Footer />
    </>
  );
};

export default ProductScreen;
