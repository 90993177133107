import React, {Fragment} from 'react';
import { Routes, Route, Navigate} from 'react-router-dom'
import HomeScreen from './screens/HomeScreen/HomeScreen';
import ProductScreen from './screens/OrderingScreens/ProductScreen';
import CartScreen from './screens/OrderingScreens/CartScreen';
import LoginScreen from './screens/User Screens/LoginScreen';
import RegisterScreen from './screens/User Screens/RegisterScreen';
import ProfileScreen from './screens/User Screens/ProfileScreen';
import ShippingScreen from './screens/OrderingScreens/ShippingScreen';
import PaymentScreen from './screens/OrderingScreens/PaymentScreen';
import PlaceOrderScreen from './screens/OrderingScreens/PlaceOrderScreen';
import OrderScreen from '../src/screens/OrderingScreens/OrderScreen';
import UserListScreen from './screens/Admin Screens/UserListScreen';
import UserEditScreen from './screens/Admin Screens/UserEditScreen';
import ProductListScreen from './screens/Admin Screens/ProductListScreen';
import ProductEditScreen from './screens/Admin Screens/ProductEditScreen';
import OrderListScreen from './screens/Admin Screens/OrderListScreen';
import MyOrdersScreen from './screens/User Screens/MyOrdersScreen';
import ShopScreen from './screens/ShopScreen/ShopScreen';
import ForgotPasswordScreen from './screens/User Screens/ForgotPasswordScreen';
import AboutScreen from './screens/InfoScreens/AboutScreen';
import PrivacyPolicyScreen from './screens/InfoScreens/PrivacyPolicyScreen';
import ContactScreen from './screens/InfoScreens/ContactScreen';
import ReturnPolicyScreen from './screens/InfoScreens/ReturnPolicyScreen';
import Maxi from './screens/CategoryScreens/Maxi';
import ShirtAndTrouser from './screens/CategoryScreens/ShirtAndTrouser';
import Lehnga from './screens/CategoryScreens/Lehnga';
import Gharara from './screens/CategoryScreens/Gharara';
import Sharara from './screens/CategoryScreens/Sharara';
import All from './screens/CategoryScreens/All';

function App() {

  return (
<Fragment>
<Routes>
<Route path='/*' element={<Navigate to='/home' />} />
        <Route path='/home' element={<HomeScreen />}/>
        <Route path='/shop' element={<ShopScreen />}/>
        <Route path='/shop/search/:keyword' element={<ShopScreen />}/>
        <Route path='/category/all' element={<All />}/>
        <Route path='/category/shirt-&-trouser' element={<ShirtAndTrouser />}/>
        <Route path='/category/maxi' element={<Maxi />}/>
        <Route path='/category/lehnga' element={<Lehnga />}/>
        <Route path='/category/gharara' element={<Gharara />}/>
        <Route path='/category/sharara' element={<Sharara />}/>
        <Route path='/privacy-policy' element={<PrivacyPolicyScreen />}/>
        <Route path='/return-policy' element={<ReturnPolicyScreen />}/>
        <Route path='/contact' element={<ContactScreen />}/>
        <Route path='/product/:id' element={<ProductScreen />}/>
        <Route path='/cart' element={<CartScreen />} />
        <Route path='/cart/:id' element={<CartScreen />} />
        <Route path='/profile' element={<ProfileScreen />}/>
        <Route path='/myorders' element={<MyOrdersScreen />}/>
        <Route path='/login' element={<LoginScreen />}/>
        <Route path='/accountrecovery' element={<ForgotPasswordScreen />}/>
        <Route path='/register' element={<RegisterScreen />}/>
        <Route path='/shipping' element={<ShippingScreen />}/>
        <Route path='/payment' element={<PaymentScreen />}/>
        <Route path='/placeorder' element={<PlaceOrderScreen />}/>
        <Route path='/order/:id' element={<OrderScreen />}/>
        <Route path='/admin/userlist' element={<UserListScreen />}/>
        <Route path='/admin/user/:id/edit' element={<UserEditScreen />}/>
        <Route path='/admin/productlist' element={<ProductListScreen/>}/>
        <Route path='/admin/product/:id/edit' element={<ProductEditScreen/>}/>
        <Route path='/admin/orderlist' element={<OrderListScreen />}/>
</Routes>        
</Fragment>
  );
}

export default App;
