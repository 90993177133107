import React, {useRef} from 'react'
import PhoneInput from 'react-phone-number-input'
import './PhoneNumber.css'


const PhoneNumber = (props) => {

    const inputNumber = useRef(null)

    const onFieldChange= () => {
      props.onChange(inputNumber.current.value)
    }

  return (
<PhoneInput
      international
      limitMaxLength
      maxLength="36"
      value={props.value}
      pattern=".{12,}" 
      ref={inputNumber}
      required={true}
      onChange={onFieldChange}
      />

  )
}

export default PhoneNumber