import React, { Fragment, useEffect, useState } from "react";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import {
  listProductDetails,
  updateProduct,
} from "../../actions/productActions";
import FormContainer from "../../components/FormContainer";
import Footer from "../../components/Footer_/Footer";
import Header from "../../components/Header_/Header";
import axios from "axios";

const ProductEditScreen = () => {
  const params = useParams();
  const productId = params.id;

  const [name, setName] = useState("");
  const [uniqueId, setUniqueId] = useState("");
  const [price, setPrice] = useState(0);
  const [sellPrice, setSellPrice] = useState(0);
  const [shippingPrice, setShippingPrice] = useState(0);
  const [image, setImage] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [image4, setImage4] = useState("");
  const [image5, setImage5] = useState("");
  const [image6, setImage6] = useState("");
  const [sizeChart, setSizeChart] = useState("");
  const [category, setCategory] = useState("");
  const [gender, setGender] = useState("");
  const [collectionName, setCollectionName] = useState("");
  const [season, setSeason] = useState("");
  const [designer, setDesigner] = useState("");
  const [size1, setSize1] = useState("");
  const [size2, setSize2] = useState("");
  const [size3, setSize3] = useState("");
  const [size4, setSize4] = useState("");
  const [size5, setSize5] = useState("");
  const [recommended, setRecommended] = useState(null);
  const [description, setDescription] = useState("");
  const [countInStock, setCountInStock] = useState(0);
  const [uploading, setUploading] = useState(false);

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const productUpdate = useSelector((state) => state.productUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate;

  const dispatch = useDispatch();
  const history = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch({ type: "PRODUCT_UPDATE_RESET" });

    if (!userInfo || !userInfo.isAdmin) {
      history("/login");
    }
    if (successUpdate) {
      history("/admin/productlist");
      window.location.reload();
    } else {
      if (!product.name || product._id !== productId) {
        dispatch(listProductDetails(productId));
      } else {
        setName(product.name);
        setUniqueId(product.uniqueId);
        setPrice(product.price);
        setSellPrice(product.sellPrice);
        setShippingPrice(product.shippingPrice);
        setDescription(product.description);
        setImage(product.image);
        setImage2(product.image2);
        setImage3(product.image3);
        setImage4(product.image4);
        setImage5(product.image5);
        setImage6(product.image6);
        setSizeChart(product.sizeChart);
        setCountInStock(product.countInStock);
        setCategory(product.category);
        setGender(product.gender);
        setCollectionName(product.collectionName);
        setDesigner(product.designer);
        setSeason(product.season);
        setSize1(product.size1);
        setSize2(product.size2);
        setSize3(product.size3);
        setSize4(product.size4);
        setSize5(product.size5);
        setRecommended(product.recommended);
      }
    }
  }, [dispatch, history, product, productId, successUpdate, userInfo]);

  const uploadImg1Handler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);
    try {
      const config = { Headers: { "Content-Type": "multipart/form-data" } };
      const { data } = await axios.post("/api/upload", formData, config);
      setImage(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const uploadImg2Handler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);
    try {
      const config = { Headers: { "Content-Type": "multipart/form-data" } };
      const { data } = await axios.post("/api/upload", formData, config);
      setImage2(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const uploadImg3Handler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);
    try {
      const config = { Headers: { "Content-Type": "multipart/form-data" } };
      const { data } = await axios.post("/api/upload", formData, config);
      setImage3(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const uploadImg4Handler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);
    try {
      const config = { Headers: { "Content-Type": "multipart/form-data" } };
      const { data } = await axios.post("/api/upload", formData, config);
      setImage4(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const uploadImg5Handler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);
    try {
      const config = { Headers: { "Content-Type": "multipart/form-data" } };
      const { data } = await axios.post("/api/upload", formData, config);
      setImage5(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const uploadImg6Handler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);
    try {
      const config = { Headers: { "Content-Type": "multipart/form-data" } };
      const { data } = await axios.post("/api/upload", formData, config);
      setImage6(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const uploadSizeChartHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);
    try {
      const config = { Headers: { "Content-Type": "multipart/form-data" } };
      const { data } = await axios.post("/api/upload", formData, config);
      setSizeChart(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const formSubmitHander = (event) => {
    event.preventDefault();

    dispatch(
      updateProduct({
        _id: productId,
        name,
        uniqueId,
        image,
        image2,
        image3,
        image4,
        image5,
        image6,
        sizeChart,
        description,
        price,
        sellPrice,
        shippingPrice,
        category,
        gender,
        designer,
        collectionName,
        season,
        size1,
        size2,
        size3,
        size4,
        size5,
        recommended,
        countInStock,
      })
    );
  };

  return (
    <>
      <Header />
      <Fragment>
        <div className="main">
          <Container>
            <Link to="/admin/productlist" className="btn btn-dark my-3">
              Go Back
            </Link>
            <FormContainer>
              <h1>Edit Product</h1>
              {loadingUpdate && <Loader />}
              {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
              {loading ? (
                <Loader />
              ) : error ? (
                <Message variant="danger">{error}</Message>
              ) : (
                <Form onSubmit={formSubmitHander}>
                  <Form.Group className="mb-3" controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="name"
                      placeholder="Enter Name"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="id">
                    <Form.Label>Unique ID</Form.Label>
                    <Form.Control
                      type="id"
                      placeholder="Enter Unique ID"
                      value={uniqueId}
                      onChange={(event) => setUniqueId(event.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="price">
                    <Form.Label>Price</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Price"
                      value={price}
                      onChange={(event) => setPrice(event.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="price">
                    <Form.Label>Sale Price</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Price"
                      value={sellPrice}
                      onChange={(event) => setSellPrice(event.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="price">
                    <Form.Label>Shipping Price</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Shipping Price"
                      value={shippingPrice}
                      onChange={(event) => setShippingPrice(event.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="image">
                    <Form.Label>Banner Image</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Image URL"
                      value={image}
                    ></Form.Control>
                    <Form.Control
                      type="file"
                      id="image-file"
                      label="Choose File"
                      custom
                      onChange={uploadImg1Handler}
                    ></Form.Control>
                    {uploading && <Spinner />}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="image">
                    <Form.Label>Second Image</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Image URL"
                      value={image2}
                    ></Form.Control>
                    <Form.Control
                      type="file"
                      id="image-file"
                      label="Choose File"
                      custom
                      onChange={uploadImg2Handler}
                    ></Form.Control>
                    {uploading && <Spinner />}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="image">
                    <Form.Label>Third Image</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Image URL"
                      value={image3}
                    ></Form.Control>
                    <Form.Control
                      type="file"
                      id="image-file"
                      label="Choose File"
                      custom
                      onChange={uploadImg3Handler}
                    ></Form.Control>
                    {uploading && <Spinner />}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="image">
                    <Form.Label>Fourth Image</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Image URL"
                      value={image4}
                    ></Form.Control>
                    <Form.Control
                      type="file"
                      id="image-file"
                      label="Choose File"
                      custom
                      onChange={uploadImg4Handler}
                    ></Form.Control>
                    {uploading && <Spinner />}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="image">
                    <Form.Label>Fifth Image</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Image URL"
                      value={image5}
                    ></Form.Control>
                    <Form.Control
                      type="file"
                      id="image-file"
                      label="Choose File"
                      custom
                      onChange={uploadImg5Handler}
                    ></Form.Control>
                    {uploading && <Spinner />}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="image">
                    <Form.Label>Sixth Image</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Image URL"
                      value={image6}
                    ></Form.Control>
                    <Form.Control
                      type="file"
                      id="image-file"
                      label="Choose File"
                      custom
                      onChange={uploadImg6Handler}
                    ></Form.Control>
                    {uploading && <Spinner />}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="image">
                    <Form.Label>Size Chart</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Image URL"
                      value={sizeChart}
                    ></Form.Control>
                    <Form.Control
                      type="file"
                      id="image-file"
                      label="Choose File"
                      custom
                      onChange={uploadSizeChartHandler}
                    ></Form.Control>
                    {uploading && <Spinner />}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="description">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Description"
                      value={description}
                      onChange={(event) => setDescription(event.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="designer">
                    <Form.Label>Designer</Form.Label>
                    <Form.Select
                      type="text"
                      value={designer}
                      onChange={(e) => setDesigner(e.target.value)}
                    >
                      <option value="MARIA-B">MARIA-B</option>
                      <option value="QALAMKAR">QALAMKAR</option>
                      <option value="ANAYA">ANAYA</option>
                      <option value="AFROZEH">AFROZEH</option>
                      <option value="SADIA">SADIA</option>
                      <option value="GULAL">GULAL</option>
                      <option value="BREEZA">BREEZA</option>
                      <option value="ASIM JOFA">ASIM JOFA</option>
                      <option value="uncategorized">uncategorized</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="collection">
                    <Form.Label>Collection</Form.Label>
                    <Form.Select
                      type="text"
                      value={collectionName}
                      onChange={(e) => setCollectionName(e.target.value)}
                    >
                      <option value="Summer collection">
                        Summer collection
                      </option>
                      <option value="Mid summer collection">
                        Mid summer collection
                      </option>
                      <option value="Luxery summer collection">
                        Luxery summer collection
                      </option>
                      <option value="Winter collection">
                        Winter collection
                      </option>
                      <option value="Luxery winter collection">
                        Luxery winter collection
                      </option>
                      <option value="Velvet collection">
                        Velvet collection
                      </option>
                      <option value="Shawl collection">Shawl collection</option>
                      <option value="Sateen collection">Sateen collection</option>
                      <option value="Wedding collection">Wedding collection</option>
                      <option value="Linen collection">Linen collection</option>
                      <option value="uncategorized">uncategorized</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="category">
                    <Form.Label>Category</Form.Label>
                    <Form.Select
                      type="text"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value="Maxi">Maxi</option>
                      <option value="Shirt And Trouser">Shirt & Trouser</option>
                      <option value="Lehnga">Lehnga</option>
                      <option value="Gharara">Gharara</option>
                      <option value="Sharara">Sharara</option>
                      <option value="uncategorized">uncategorized</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="season">
                    <Form.Label>Season</Form.Label>
                    <Form.Select
                      type="text"
                      value={season}
                      onChange={(e) => setSeason(e.target.value)}
                    >
                      <option value="Winter">Winter</option>
                      <option value="Summer">Summer</option>
                      <option value="Wedding">Wedding</option>
                      <option value="uncategorized">uncategorized</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="gender">
                    <Form.Label>Gender</Form.Label>
                    <Form.Select
                      type="text"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <option value="men">Men</option>
                      <option value="women">Women</option>
                      <option value="none">None</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="colour2">
                    <Form.Label>Sizes</Form.Label>
                    {/* <Form.Check
                      checked={size1 === "XS"}
                      label="XS"
                      onChange={(e) =>
                        e.target.checked ? setSize1("XS") : setSize1("none")
                      }
                    /> */}
                    <Form.Check
                      checked={size2 === "S"}
                      label="S"
                      onChange={(e) =>
                        e.target.checked ? setSize2("S") : setSize2("none")
                      }
                    />
                    <Form.Check
                      checked={size3 === "M"}
                      label="M"
                      onChange={(e) =>
                        e.target.checked ? setSize3("M") : setSize3("none")
                      }
                    />

                    <Form.Check
                      checked={size4 === "L"}
                      label="L"
                      onChange={(e) =>
                        e.target.checked ? setSize4("L") : setSize4("none")
                      }
                    />
                    <Form.Check
                      checked={size5 === "XL"}
                      label="XL"
                      onChange={(e) =>
                        e.target.checked ? setSize5("XL") : setSize5("none")
                      }
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="colour2">
                    <Form.Label>Recommendation</Form.Label>
                    <Form.Check
                      checked={recommended === true}
                      label="Recommend"
                      onChange={(e) =>
                        e.target.checked
                          ? setRecommended(true)
                          : setRecommended(false)
                      }
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="countInStock">
                    <Form.Label>Count In Stock</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Set CountInStock "
                      value={countInStock}
                      onChange={(event) => setCountInStock(event.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <br></br>
                  <Button type="submit" variant="primary">
                    Update
                  </Button>
                </Form>
              )}
            </FormContainer>
          </Container>
        </div>
      </Fragment>
      <Footer />
    </>
  );
};

export default ProductEditScreen;
