import React, { useState } from "react";
import { Row, Col, Container, Form, Spinner } from "react-bootstrap";
import styles from "./ContactScreen.module.css";
import Footer from "../../components/Footer_/Footer";
import Header from "../../components/Header_/Header";
import axios from "axios";

const ContactScreen = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const formSubmitHandler = (event) => {
    event.preventDefault();
    setLoading(true);
    axios
      .post("/send-contact-form", {
        name,
        email,
        phoneNum,
        message,
      })
      .then((response) => {
        if (response.data.msg === "success") {
          setError(false);
          setSuccess("Form Submitted Successfully!");
          setLoading(false);
          setEmail("");
        } else if (response.data.msg === "fail") {
          setSuccess(false);
          setError("Email failed to send, try again by reloading the page.");
          setLoading(false);
          setEmail("");
        }
      });
  };
  return (
    <>
      <Header />
      <div className="main">
        <Container>
          <Row className="gy-4 gx-5">
            <Col lg={6}>
              <div className={styles.contentCol}>
                <h1 className="h1_main pb-4">Get in touch Lets Talk!</h1>
                <p className="para_main pb-4">
                  Feel free to send us your message. We look forward to
                  connecting with you!
                </p>
                <Row>
                  <Col className="pt-3" lg={6}>
                    <div className={styles.info}>
                      <img src="/images/phone.svg" />
                      <p className="para_main">+1 647-687-0047</p>
                    </div>
                  </Col>
                  <Col className="pt-3" lg={6}>
                    <div className={styles.info}>
                      <img src="/images/mail.svg" />
                      <a
                        style={{ textDecoration: "none" }}
                        href="mailto:abqarees@gmail.com"
                      >
                        <p className="para_main">abqarees@gmail.com</p>
                      </a>
                    </div>
                  </Col>
                  <Col className="pt-3" lg={12}>
                    <a
                      style={{ textDecoration: "none" }}
                      target="no_blank"
                      href="https://www.google.com/maps/dir//20+Kingsbridge+Garden+Cir,+Mississauga,+ON+L5R+4A4,+Canada/@43.602504,-79.7312516,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x882b4715c4684f5b:0x5c579084172f2149!2m2!1d-79.6488509!2d43.6025336?entry=ttu"
                    >
                      <div className={styles.info}>
                        <img src="/images/location.svg" />
                        <p className="para_main">
                          20 Kingsbridge Garden Circle, Unit B1, Mississauga,
                          ON, L5R 3K7, Canada.
                        </p>
                      </div>
                    </a>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={6}>
              <div className={styles.formCol}>
                <h1 className="h1_main pb-2">Let’s get in touch!</h1>
                <p className="para_main pb-5">
                  Let’s discuss your project and find out what we
                </p>
                {loading ? (
                  <div className="row pb-4">
                    <div className="col-12">
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ color: "black" }}
                      />
                    </div>
                  </div>
                ) : success ? (
                  <div className="row pb-4">
                    <div className="col-12">
                      <p className="para_main" style={{ color: "black" }}>
                        {success}
                      </p>
                    </div>
                  </div>
                ) : (
                  error && (
                    <div className="row pb-4">
                      <div className="col-12">
                        <p className="para_main" style={{ color: "black" }}>
                          {error}
                        </p>
                      </div>
                    </div>
                  )
                )}
                <form onSubmit={formSubmitHandler}>
                  <input
                    maxLength="12"
                    type="text"
                    placeholder="Your Full Name"
                    pattern=".{3,}"
                    required
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                  <input
                    maxLength="36"
                    type="email"
                    placeholder="Email"
                    pattern=".{3,}"
                    required
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                  <input
                    maxLength="36"
                    type="text"
                    placeholder="Phone Number"
                    pattern=".{3,}"
                    required
                    value={phoneNum}
                    onChange={(event) => setPhoneNum(event.target.value)}
                  />
                  <input
                    style={{ resize: "none" }}
                    as="textarea"
                    maxLength="800"
                    type="text"
                    placeholder="Your Message"
                    required
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                  />
                  <button className="button_main mt-3" type="submit">
                    <span>Submit</span>
                  </button>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default ContactScreen;
