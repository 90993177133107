import React, { Fragment, useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../../components/FormContainer";
import { saveShippingAddress } from "../../actions/cartActions";
import CheckoutSteps from "../../components/ProgressBar/CheckoutSteps";
import PhoneNumber from "../../components/PhoneInput/PhoneNumber";
import { getUserDetails } from "../../actions/userActions";
import Footer from "../../components/Footer_/Footer";
import Header from "../../components/Header_/Header";

const ShippingScreen = () => {
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;
  const { cartItems } = cart;

  const userDetails = useSelector((state) => state.userDetails);
  const { firstName, lastName, email, phoneNum } = userDetails;

  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState(email);
  const [PhoneNum, setPhoneNum] = useState(phoneNum);
  const [address, setAddress] = useState(shippingAddress.address);
  const [province, setProvince] = useState(shippingAddress.province);
  const [city, setCity] = useState(shippingAddress.city);
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode);
  const [country, setCountry] = useState(shippingAddress.country);

  const dispatch = useDispatch();
  const history = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      history("/login");
    } else {
      if (cartItems.length === 0) {
        history("/shop");
      }
    }

    if (!firstName) {
      dispatch(getUserDetails("profile"));
    } else {
      setFirstName(firstName);
      setLastName(lastName);
      setEmail(email);
      setPhoneNum(phoneNum);
    }
  }, [firstName, lastName, email, phoneNum, dispatch, history, userInfo]);

  const formSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(
      saveShippingAddress({
        address,
        firstName: FirstName,
        lastName: LastName,
        email: Email,
        phoneNum: PhoneNum,
        province,
        city,
        postalCode,
        country,
      })
    );
    history("/payment");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const callback = (phoneNumber) => {
    setPhoneNum(phoneNumber);
  };

  return (
    <>
      <Header />
      <Fragment>
        <Container className="formScreen formScreenNoMargin">
          <div className="main">
            <CheckoutSteps classname="onShipping" />
            <FormContainer>
              <h1>Shipping</h1>

              <Form
                as="form"
                className="form text-center"
                onSubmit={formSubmitHandler}
              >
                <Form.Group className="mb-3 text-start" controlId="name">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    maxLength="12"
                    type="text"
                    placeholder="Enter First Name"
                    pattern=".{3,}"
                    required
                    value={FirstName}
                    onChange={(event) => setFirstName(event.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group className="mb-3 text-start" controlId="name">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    maxLength="12"
                    type="text"
                    placeholder="Enter Last Name"
                    pattern=".{3,}"
                    required
                    value={LastName}
                    onChange={(event) => setLastName(event.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group className="mb-3 text-start" controlId="email">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email"
                    pattern=".{3,}"
                    maxLength="36"
                    required
                    value={Email}
                    onChange={(event) => setEmail(event.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group className="mb-3 text-start phoneInput">
                  <Form.Label>Phone Number</Form.Label>
                  <PhoneNumber
                    onShippingScreen={true}
                    value={PhoneNum}
                    onChange={callback}
                  />
                  <p>
                    <strong>Note:</strong> We only use this number for order's
                    confirmation or shipping issue.
                  </p>
                </Form.Group>

                <Form.Group className="mb-3 text-start" controlId="address">
                  <Form.Label>Street Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Street Address"
                    maxLength="100"
                    pattern=".{4,}"
                    required
                    value={address}
                    onChange={(event) => setAddress(event.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group className="mb-3 text-start" controlId="province">
                  <Form.Label>Province/State</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter province or state"
                    maxLength="100"
                    pattern=".{2,}"
                    required
                    value={province}
                    onChange={(event) => setProvince(event.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group className="mb-3 text-start" controlId="city">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter City"
                    maxLength="100"
                    pattern=".{2,}"
                    required
                    value={city}
                    onChange={(event) => setCity(event.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group className="mb-3 text-start" controlId="postalCode">
                  <Form.Label>Postal Code/Zip Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Postal Code/Zip Code"
                    maxLength="100"
                    pattern=".{2,}"
                    required
                    value={postalCode}
                    onChange={(event) => setPostalCode(event.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group className="mb-3 text-start" controlId="country">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Country"
                    maxLength="100"
                    pattern=".{2,}"
                    required
                    value={country}
                    onChange={(event) => setCountry(event.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group className="mb-3 text-start securityText">
                  <p className="text-start">
                    <i className="fa-solid fa-lock" /> All data is transmitted
                    encrypted via a secure TLS connection. If you have any
                    concers, read our
                    <Link to="/privacy-policy"> Privacy Policy</Link>
                  </p>
                </Form.Group>
                <br></br>
                <button type="submit">Continue</button>
              </Form>
            </FormContainer>
          </div>
        </Container>
      </Fragment>
      <Footer />
    </>
  );
};

export default ShippingScreen;
