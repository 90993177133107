import React, { useEffect, useState } from "react";
import Header from "../../components/Header_/Header";
import CommonHero from "../../components/CommonHero_/CommonHero";
import DynamicProducts from "../../components/DynamicProducts/DynamicProducts";
import Footer from "../../components/Footer_/Footer";
import { useParams } from "react-router-dom";

const All = () => {
  const [currentParams, setCurrentParams] = useState(null);
  const params = useParams();

  useEffect(() => {
    if (params && !currentParams) {
      setCurrentParams(params);
    } else if (currentParams !== params) {
      window.location.reload();
    }
  }, [params]);

  return (
    <>
      <Header />
      <CommonHero
        image="/images/shop-hero.jpg"
        span={`Explore Our Categories`}
        text="Craft your unique winter look with our Unstitched Winter'23 Collection where style knows no limits.Craft your unique winter look with our Unstitched Winter'23 Collection where style knows no limits.Craft your unique winter look with our Unstitched Winter'23 Collection where style knows no limits."
      />
      <DynamicProducts />
      <Footer />
    </>
  );
};

export default All;