import React,{useEffect} from 'react'
import { myOrdersListAction } from '../../actions/orderActions'
import { LinkContainer } from 'react-router-bootstrap'
import{Row, Button, Table, Container} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import { useNavigate} from 'react-router-dom'
import Message  from '../../components/Message'
import Loader from '../../components/Loader'
import Footer from '../../components/Footer_/Footer'
import Header from '../../components/Header_/Header'


const MyOrdersScreen = () => {
    

    const dispatch = useDispatch()
    const history = useNavigate()

    const userLogin = useSelector((state)=> state.userLogin) 
    const {userInfo}= userLogin

    const myOrdersList = useSelector((state)=> state.myOrdersList) 
    const {orders, loading: loadingOrders, error: errorOrders}= myOrdersList



    useEffect(()=>{

        if(!userInfo){
                    history('/login')
                }
        else{
            dispatch({type:'ORDER_DETAILS_RESET'}) 
            dispatch(myOrdersListAction())
        }
        
        }, [dispatch, history, userInfo])
        


  return (
    <>
    <Header/>
    <Container className='user-screens-mg-top user-screens'>
    <Row >
        <h1>My Orders</h1>
        <div className='dek-table'>
        {loadingOrders ? <Loader /> : errorOrders ? <Message variant='danger'>{errorOrders}</Message>:
        (<Table striped bordered hover responsive className='table-sm'>
          <thead><tr>
            <th>ID</th>
            <th>DATE</th>
            <th>TOTAL</th>
            <th>PAID</th>
            <th>DELIVERED</th>
            <th></th>
            </tr>
         </thead>
         <tbody>
          {orders.map((order)=>
          <tr key={order._id}>
            <td>{order._id}</td>
             <td>{order.createdAt.substring(0, 10)}</td> 
            <td>${order.totalPrice}</td>
            {order.paymentMethod==='Paypal or Credit or Debit' ? <td>{order.isPaid ? order.paidAt.substring(0, 10):(
              <i className='fas fa-times' style={{color:'red'}} />
            )} 
            </td> : <td>{order.isDelivered ? order.deliveredAt.substring(0, 10):(
              <i className='fas fa-times' style={{color:'red'}} />
            )}
            </td> }
            <td>{order.isDelivered ? order.deliveredAt.substring(0, 10):(
              <i className='fas fa-times' style={{color:'red'}} />
            )}
            </td>
            <td>
            <LinkContainer to={`/order/${order._id}`}>
              <Button className='btn-sm' variant='light'>Details</Button>
            </LinkContainer>
            </td>
          </tr>)}
         </tbody>
        </Table>)}
        </div>
<div className='mob-table'>
        {loadingOrders ? <Loader /> : errorOrders ? <Message variant='danger'>{errorOrders}</Message>:
        (<Table striped bordered hover responsive className='table-sm'>
          <thead><tr>
            <th>ID</th>
            <th></th>
            </tr>
         </thead>
         <tbody>
          {orders.map((order)=>
          <tr key={order._id}>
            <td>{order._id}</td>
            <td>
            <LinkContainer to={`/order/${order._id}`}>
              <Button className='btn-sm' variant='light'>Details</Button>
            </LinkContainer>
            </td>
          </tr>)}
         </tbody>
        </Table>)}
        </div>
    </Row>
    </Container>
    <Footer/>
    </>
  )
}

export default MyOrdersScreen