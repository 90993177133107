import React, {Fragment, useEffect} from 'react'
import{Button, Container, Table} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { useNavigate } from 'react-router-dom'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { deleteUser, listUsers } from '../../actions/userActions'
import Footer from '../../components/Footer_/Footer'
import Header from '../../components/Header_/Header'


const UserListScreen = () => {

    const dispatch = useDispatch()
    const history = useNavigate()

    const userList = useSelector((state)=>state.userList)
    const{loading, error, users}= userList

    const userLogin = useSelector((state)=>state.userLogin)
    const{userInfo}= userLogin

    const userDelete = useSelector((state)=>state.userDelete)
    const{success:successDelete}= userDelete


    useEffect(()=>{

if(userInfo && userInfo.isAdmin){
        dispatch(listUsers())
    }
else{
    history('/login')
}
    }
    ,[dispatch, history, successDelete, userInfo])


const deleteHandler=(id)=>{
    if(window.confirm('Are You Sure You Want To Delete This User?')){
        dispatch(deleteUser(id))
    } 
}

  return (
    <>
    <Header/>
    <Fragment>
        <Container className='admin-screens-mg-top'>
    <div>Users</div>
    {loading ? <Loader/> : error ? <Message variant='danger'>{error}</Message>: (

<Table striped bordered hover responsive className='table-sm'>
<thead>
    <tr>
            <th>ID</th>
            <th>NAME</th>
            <th>EMAIL</th>
            <th>ADMIN</th>
            <th></th>
    </tr>
</thead>
<tbody>
    {users.map(user=>(
        <tr key={user._id}>
            <td>{user._id}</td>
            <td>{`${user.firstName} ${user.lastName}`}</td>
            <td><a href={`mailto:${user.email}`}>{user.email}</a></td>
            <td>{user.isAdmin ? (<i className='fas fa-check' style={{color:'green'}}></i>
            ) : (
            <i className='fas fa-times' style={{color:'red'}}></i>)}
            </td>
            <td>
                <LinkContainer to={`/admin/user/${user._id}/edit`}>
                    <Button variant='light' className='btn-sm'>
                        <i className='fas fa-edit'></i>
                    </Button>
                </LinkContainer>
                <Button variant='danger' className='btn-sm' onClick={()=> deleteHandler(user._id)}>
                    <i className='fas fa-trash'></i>
                </Button>
            </td>
        </tr>
    ))}
</tbody>

</Table>

    )}
    </Container>
    </Fragment>
    <Footer/>
    </>
  )
}

export default UserListScreen