import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer_/Footer";
import Header from "../../components/Header_/Header";
import "./Policies.css";

const ReturnPolicyScreen = () => {
  return (
    <>
      <Header />
      <Container className="root">
        <div className="main">
          <h2 style={{ width: "100%" }} className="h2_main text-start pb-4">
            Return Policy
          </h2>
          <p className="para_main">
            Items purchased from our online store can be sent back for exchange.
            You can contact our customer service if you wish to send the items
            back through courier.
            <br />
            <br />
            Abqaree allows its customers to exchange the purchased items subject
            to conditions. The exchange of item will be allowed if:
            <br />
            <br />
            The customer has submitted a request for exchange (via email, phone
            call, SMS or WhatsApp) within 7 working days of receipt of the
            purchased item;
            <br />
            <br />
            The customer in his/her request for exchange has stated the
            reason(s) why he/she wishes to exchange the item;
            <br />
            <br />
            The item is in its original packing and the price tag is intact;
            <br />
            <br />
            The original invoice of the item he/she wishes to exchange is sent
            along with the item;
            <br />
            <br />
            It has no emits, odours, perfume scents, stains or anything which
            suggests the item was used or washed.
            <br />
            <br />
            Items bought on sale cannot be exchanged. If an item bought on
            regular price goes on sale, it will be exchanged at sale price.
            <br />
            <br />
            Abqaree has the right, at its sole discretion, to accept or deny the
            request for exchange.
            <br />
            <br />
            Upon the acceptance of request for exchange, the customer will be
            notified by our customer service.
            <br />
            <br />
            ABQAREE strictly follows ‘NO refund’ policy. The option of cashback
            is not available. The Exchange Policy will be applicable once the
            customer has placed the order and has received the parcel or payment
            has been processed.
          </p>
          <h2
            style={{ width: "100%" }}
            className="h2_main text-start pb-4 pt-4"
          >
            Damage & Claims
          </h2>
          <p>
            The exchange of Damaged item(s) will be allowed if the item(s)
            received has any kind of manufacturing defect, shipment of wrong
            size or wrong item. The complaint has to be raised within 2 working
            days along with paper invoice (via call, message or email) after
            receiving the parcel. Please allow one week for the processing of
            damage and claims.
          </p>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default ReturnPolicyScreen;
