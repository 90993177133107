import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { listProductQuery, listProducts } from "../../actions/productActions";
import Message from "../../components/Message";
import ProductsLoader from "../../components/ProductLoader/ProductsLoader";
import styles from "./DynamicProducts.module.css";
import "./DynamicProducts.css";
import { useNavigate, useParams } from "react-router-dom";
import IdvProduct from "../IdvProduct_/IdvProduct";
import Loader from "../Loader";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";

const DynamicProducts = ({ Ref, gen, cate, bran }) => {
  const [query, setQuery] = useState(undefined);
  const [collectionName, setCollectionName] = useState(gen ? gen : undefined);
  const [category, setCategory] = useState(cate ? cate : "");
  const [brand, setBrand] = useState(bran ? bran : "none");
  const history = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const productQuery = useSelector((state) => state.productQuery);
  const { loading, error, products } = productQuery;

  const productList = useSelector((state) => state.productList);
  const { loading: Loading, products: Products } = productList;

  const keyword = params.keyword;

  useEffect(() => {
    dispatch(listProducts());
  }, [gen, collectionName, bran, brand, dispatch]);

  useEffect(() => {
    if (keyword || category || collectionName || brand) {
      setQuery(keyword ? keyword : undefined);
      dispatch(
        listProductQuery(
          keyword ? keyword : " ",
          collectionName ? collectionName : " ",
          category ? category : " ",
          brand ? brand : "none"
        )
      );
    } else {
      dispatch(listProductQuery());
    }
  }, [dispatch, keyword, params, collectionName, category, gen, bran, brand]);

  const querySubmitHandler = (e) => {
    e.preventDefault();
    if (brand !== "none" && query.trim()) {
      history(`/shop/${brand}/search/${query}`);
    } else {
      if (brand !== "none") {
        history(`/shop/${brand}`);
      } else {
        if (query.trim()) {
          history(`/shop/search/${query}`);
        } else {
          history("/shop");
        }
      }
    }
  };

  const collectionHandler = (val) => {
    setCollectionName(val === "none" ? undefined : val);
  };

  return (
    <Fragment>
      <div ref={Ref} className="main">
        <Container>
          <Row className={styles.filter}>
            <div className={styles.search}>
              <form
                onSubmit={querySubmitHandler}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <input
                  maxLength="24"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  type="text"
                  placeholder="Search"
                />{" "}
                <span>
                  <img
                    onClick={querySubmitHandler}
                    type="submit"
                    src="/images/search.svg"
                  />
                </span>
              </form>
            </div>
            <div className={styles.gender}>
              <select
                value={collectionName}
                onChange={(e) => collectionHandler(e.target.value)}
              >
                <option value="none">All</option>
                <option value="Summer collection">Summer collection</option>
                <option value="Mid summer collection">
                  Mid summer collection
                </option>
                <option value="Luxery summer collection">
                  Luxery summer collection
                </option>
                <option value="Winter collection">Winter collection</option>
                <option value="Luxery winter collection">
                  Luxery winter collection
                </option>
                <option value="Velvet collection">Velvet collection</option>
                <option value="Shawl collection">Shawl collection</option>
                <option value="Sateen collection">Sateen collection</option>
                <option value="Wedding collection">Wedding collection</option>
                <option value="Linen collection">Linen collection</option>
              </select>
            </div>
          </Row>

          <Row className="pt-5">
            <Col lg={4}>
              <div className={styles.categories}>
                <h5 className="h5_main">Category</h5>

                <div className={styles.mobList}>
                  <Swiper
                    pagination={{
                      dynamicBullets: true,
                      clickable: true,
                    }}
                    slidesPerView={2}
                    modules={[Pagination]}
                    className={styles.swiper}
                  >
                    <SwiperSlide className={styles.swiperSlide}>
                      <div
                        className={
                          category === ""
                            ? `${styles.boxActive} ${styles.box}`
                            : styles.box
                        }
                        onClick={() => setCategory("")}
                      >
                        <h6>All</h6>
                        {Loading ? (
                          <span>
                            {" "}
                            <Loader width="15px" height="15px" />
                          </span>
                        ) : (
                          <span>
                            (0
                            {collectionName && brand === "none"
                              ? Products.filter(
                                  (item) =>
                                    item.collectionName === collectionName
                                ).length
                              : !collectionName && brand !== "none"
                              ? Products.filter((item) => item.brand === brand)
                                  .length
                              : collectionName && brand !== "none"
                              ? Products.filter(
                                  (item) =>
                                    item.collectionName === collectionName &&
                                    item.brand === brand
                                ).length
                              : Products.length}
                            )
                          </span>
                        )}
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                      <div
                        className={
                          category === "Maxi"
                            ? `${styles.boxActive} ${styles.box}`
                            : styles.box
                        }
                        onClick={() => setCategory("Maxi")}
                      >
                        <h6>Maxi</h6>
                        {Loading ? (
                          <span>
                            <Loader width="15px" height="15px" />
                          </span>
                        ) : (
                          <span>
                            (0
                            {
                              Products.filter((item) =>
                                collectionName && brand === "none"
                                  ? item.collectionName === collectionName &&
                                    item.category === "Maxi"
                                  : !collectionName && brand !== "none"
                                  ? item.brand === brand &&
                                    item.category === "Maxi"
                                  : collectionName && brand !== "none"
                                  ? item.brand === brand &&
                                    item.collectionName === collectionName &&
                                    item.category === "Maxi"
                                  : item.category === "Maxi"
                              ).length
                            }
                            )
                          </span>
                        )}
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                      <div
                        className={
                          category === "Shirt And Trouser"
                            ? `${styles.boxActive} ${styles.box}`
                            : styles.box
                        }
                        onClick={() => setCategory("Shirt And Trouser")}
                      >
                        <h6>Shirt & Trouser</h6>
                        {Loading ? (
                          <span>
                            <Loader width="15px" height="15px" />{" "}
                          </span>
                        ) : (
                          <span>
                            (0
                            {
                              Products.filter((item) =>
                                collectionName && brand === "none"
                                  ? item.collectionName === collectionName &&
                                    item.category === "Shirt And Trouser"
                                  : !collectionName && brand !== "none"
                                  ? item.brand === brand &&
                                    item.category === "Shirt And Trouser"
                                  : collectionName && brand !== "none"
                                  ? item.brand === brand &&
                                    item.collectionName === collectionName &&
                                    item.category === "Shirt And Trouser"
                                  : item.category === "Shirt And Trouser"
                              ).length
                            }
                            )
                          </span>
                        )}
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                      <div
                        className={
                          category === "Lehnga"
                            ? `${styles.boxActive} ${styles.box}`
                            : styles.box
                        }
                        onClick={() => setCategory("Lehnga")}
                      >
                        <h6>Lehnga</h6>
                        {Loading ? (
                          <span>
                            <Loader width="15px" height="15px" />
                          </span>
                        ) : (
                          <span>
                            (0
                            {
                              Products.filter((item) =>
                                collectionName && brand === "none"
                                  ? item.collectionName === collectionName &&
                                    item.category === "Lehnga"
                                  : !collectionName && brand !== "none"
                                  ? item.brand === brand &&
                                    item.category === "Lehnga"
                                  : collectionName && brand !== "none"
                                  ? item.brand === brand &&
                                    item.collectionName === collectionName &&
                                    item.category === "Lehnga"
                                  : item.category === "Lehnga"
                              ).length
                            }
                            )
                          </span>
                        )}
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                      <div
                        className={
                          category === "Gharara"
                            ? `${styles.boxActive} ${styles.box}`
                            : styles.box
                        }
                        onClick={() => setCategory("Gharara")}
                      >
                        <h6>Gharara</h6>
                        {Loading ? (
                          <span>
                            <Loader width="15px" height="15px" />
                          </span>
                        ) : (
                          <span>
                            (0
                            {
                              Products.filter((item) =>
                                collectionName && brand === "none"
                                  ? item.collectionName === collectionName &&
                                    item.category === "Gharara"
                                  : !collectionName && brand !== "none"
                                  ? item.brand === brand &&
                                    item.category === "Gharara"
                                  : collectionName && brand !== "none"
                                  ? item.brand === brand &&
                                    item.collectionName === collectionName &&
                                    item.category === "Gharara"
                                  : item.category === "Gharara"
                              ).length
                            }
                            )
                          </span>
                        )}
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                      <div
                        className={
                          category === "Sharara"
                            ? `${styles.boxActive} ${styles.box}`
                            : styles.box
                        }
                        onClick={() => setCategory("Sharara")}
                      >
                        <h6>Sharara</h6>
                        {Loading ? (
                          <span>
                            <Loader width="15px" height="15px" />
                          </span>
                        ) : (
                          <span>
                            (0
                            {
                              Products.filter((item) =>
                                collectionName && brand === "none"
                                  ? item.collectionName === collectionName &&
                                    item.category === "Sharara"
                                  : !collectionName && brand !== "none"
                                  ? item.brand === brand &&
                                    item.category === "Sharara"
                                  : collectionName && brand !== "none"
                                  ? item.brand === brand &&
                                    item.collectionName === collectionName &&
                                    item.category === "Sharara"
                                  : item.category === "Sharara"
                              ).length
                            }
                            )
                          </span>
                        )}
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
                <div className={styles.list}>
                  <div
                    className={
                      category === ""
                        ? `${styles.boxActive} ${styles.box}`
                        : styles.box
                    }
                    onClick={() => setCategory("")}
                  >
                    <h6>All</h6>
                    {Loading ? (
                      <span>
                        {" "}
                        <Loader width="15px" height="15px" />
                      </span>
                    ) : (
                      <span>
                        (0
                        {collectionName && brand === "none"
                          ? Products.filter(
                              (item) => item.collectionName === collectionName
                            ).length
                          : !collectionName && brand !== "none"
                          ? Products.filter((item) => item.brand === brand)
                              .length
                          : collectionName && brand !== "none"
                          ? Products.filter(
                              (item) =>
                                item.collectionName === collectionName &&
                                item.brand === brand
                            ).length
                          : Products.length}
                        )
                      </span>
                    )}
                  </div>
                  <div
                    className={
                      category === "Maxi"
                        ? `${styles.boxActive} ${styles.box}`
                        : styles.box
                    }
                    onClick={() => setCategory("Maxi")}
                  >
                    <h6>Maxi</h6>
                    {Loading ? (
                      <span>
                        <Loader width="15px" height="15px" />
                      </span>
                    ) : (
                      <span>
                        (0
                        {
                          Products.filter((item) =>
                            collectionName && brand === "none"
                              ? item.collectionName === collectionName &&
                                item.category === "Maxi"
                              : !collectionName && brand !== "none"
                              ? item.brand === brand && item.category === "Maxi"
                              : collectionName && brand !== "none"
                              ? item.brand === brand &&
                                item.collectionName === collectionName &&
                                item.category === "Maxi"
                              : item.category === "Maxi"
                          ).length
                        }
                        )
                      </span>
                    )}
                  </div>
                  <div
                    className={
                      category === "Shirt And Trouser"
                        ? `${styles.boxActive} ${styles.box}`
                        : styles.box
                    }
                    onClick={() => setCategory("Shirt And Trouser")}
                  >
                    <h6>Shirt & Trouser</h6>
                    {Loading ? (
                      <span>
                        <Loader width="15px" height="15px" />
                      </span>
                    ) : (
                      <span>
                        (0
                        {
                          Products.filter((item) =>
                            collectionName && brand === "none"
                              ? item.collectionName === collectionName &&
                                item.category === "Shirt And Trouser"
                              : !collectionName && brand !== "none"
                              ? item.brand === brand && item.category === "Shirt And Trouser"
                              : collectionName && brand !== "none"
                              ? item.brand === brand &&
                                item.collectionName === collectionName &&
                                item.category === "Shirt And Trouser"
                              : item.category === "Shirt And Trouser"
                          ).length
                        }
                        )
                      </span>
                    )}
                  </div>
                  <div
                    className={
                      category === "Lehnga"
                        ? `${styles.boxActive} ${styles.box}`
                        : styles.box
                    }
                    onClick={() => setCategory("Lehnga")}
                  >
                    <h6>Lehnga</h6>
                    {Loading ? (
                      <span>
                        <Loader width="15px" height="15px" />
                      </span>
                    ) : (
                      <span>
                        (0
                        {
                          Products.filter((item) =>
                            collectionName && brand === "none"
                              ? item.collectionName === collectionName &&
                                item.category === "Lehnga"
                              : !collectionName && brand !== "none"
                              ? item.brand === brand &&
                                item.category === "Lehnga"
                              : collectionName && brand !== "none"
                              ? item.brand === brand &&
                                item.collectionName === collectionName &&
                                item.category === "Lehnga"
                              : item.category === "Lehnga"
                          ).length
                        }
                        )
                      </span>
                    )}
                  </div>

                  <div
                    className={
                      category === "Gharara"
                        ? `${styles.boxActive} ${styles.box}`
                        : styles.box
                    }
                    onClick={() => setCategory("Gharara")}
                  >
                    <h6>Gharara</h6>
                    {Loading ? (
                      <span>
                        <Loader width="15px" height="15px" />
                      </span>
                    ) : (
                      <span>
                        (0
                        {
                          Products.filter((item) =>
                            collectionName && brand === "none"
                              ? item.collectionName === collectionName &&
                                item.category === "Gharara"
                              : !collectionName && brand !== "none"
                              ? item.brand === brand &&
                                item.category === "Gharara"
                              : collectionName && brand !== "none"
                              ? item.brand === brand &&
                                item.collectionName === collectionName &&
                                item.category === "Gharara"
                              : item.category === "Gharara"
                          ).length
                        }
                        )
                      </span>
                    )}
                  </div>
                  <div
                    className={
                      category === "Sharara"
                        ? `${styles.boxActive} ${styles.box}`
                        : styles.box
                    }
                    onClick={() => setCategory("Sharara")}
                  >
                    <h6>Sharara</h6>
                    {Loading ? (
                      <span>
                        <Loader width="15px" height="15px" />
                      </span>
                    ) : (
                      <span>
                        (0
                        {
                          Products.filter((item) =>
                            collectionName && brand === "none"
                              ? item.collectionName === collectionName &&
                                item.category === "Sharara"
                              : !collectionName && brand !== "none"
                              ? item.brand === brand &&
                                item.category === "Sharara"
                              : collectionName && brand !== "none"
                              ? item.brand === brand &&
                                item.collectionName === collectionName &&
                                item.category === "Sharara"
                              : item.category === "Sharara"
                          ).length
                        }
                        )
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12} lg={8}>
              {loading ? (
                <ProductsLoader length={6} />
              ) : error ? (
                <Message variant="danger">{error}</Message>
              ) : products.length === 0 ? (
                <h2
                  style={{ fontStyle: "normal", textTransform: "none" }}
                  className="h2_main"
                >
                  NO MATCHING ITEMS
                </h2>
              ) : (
                <Row className="gy-5">
                  {products.map((product) => (
                    <Col
                      style={{ marginBottom: "35px", height: "auto" }}
                      key={product._id}
                      sm={12}
                      lg={6}
                    >
                      <IdvProduct productPage={true} product={product} />
                    </Col>
                  ))}
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default DynamicProducts;
