import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./BlogSec.module.css";
import { Link } from "react-router-dom";

const BlogSec = () => {
  return (
    <div style={{ backgroundColor: "#F9F9F9" }} className="main">
      <Container>
        <h4 className="h4_main pb-2">All you need to know!</h4>
        <h2 className="h2_main">Stay updated with trends</h2>
        <Row className="pt-4 gy-4">
          <Col lg={4}>
            <div className={styles.box}>
              <img src="/images/blog1.jpg" />
              <h5>
                Dress Like a Mississauga Muse: The Abqaree Guide to Desi Fashion
              </h5>
              <p className="para_main">
                Find your perfect desi look in Mississauga! Abqaree's blog
                explores modern desi trends, outfit inspiration, and tips for
                embracing your inner desi diva with confidence.
              </p>
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
                to="/blog"
              >
                <h6>Read More</h6>
              </Link>
            </div>
          </Col>
          <Col lg={4}>
            <div className={styles.box}>
              <img src="/images/blog2.jpg" />
              <h5>
                Beyond the Lehenga: Discover Abqaree's Desi Fashion for Every
                Event
              </h5>
              <p className="para_main">
                From festive celebrations to casual gatherings, Abqaree has the
                perfect desi outfit for you. Explore our diverse collection and
                discover modern designs with a touch of tradition.
              </p>
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
                to="/blog"
              >
                <h6>Read More</h6>
              </Link>
            </div>
          </Col>
          <Col lg={4}>
            <div className={styles.box}>
              <img src="/images/blog3.jpg" />
              <h5>
                Here's what makes Abqaree the go-to destination for
                Mississauga's desi fashionistas
              </h5>
              <p className="para_main">
                Our collections seamlessly blend classic silhouettes with modern
                cuts and trendy embellishments. Find yourself in stunning
                lehengas, elegant anarkalis, or chic indo-western gowns, all
                designed to flatter your figure and make a statement.
              </p>
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
                to="/blog"
              >
                <h6>Read More</h6>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BlogSec;
