import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./CategoriesRow.module.css";
import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import IdvProduct from "../IdvProduct_/IdvProduct";
import { listProducts } from "../../actions/productActions";
import { Autoplay, Navigation } from "swiper";
import ProductsLoader from "../ProductLoader/ProductsLoader";
import Message from "../Message";

const CategoriesRow = ({ heading, swiper }) => {

  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;

  useEffect(() => {
    dispatch(listProducts());
  }, [dispatch]);

  return (
    <div className="main">
      <Container>
        <Row className={styles.row1}>
          <div className={styles.headingBox}>
            <h2 className="h2_main">{heading}</h2>
          </div>
          {!swiper && (
            <div className={styles.headingBox}>
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
                to="/shop"
              >
                <h4 className="h4_main">View All</h4>
              </Link>
            </div>
          )}
          {swiper && (
            <div className={styles.imgBox}>
              <img className="prev" src="/images/arrow-left_.svg" />
              <img className="next" src="/images/arrow-right.svg" />
            </div>
          )}
        </Row>

        <Row className="pt-3">
          <div className={styles.swiper_box}>
            {!swiper && (
              <Swiper
              style={{width:"100%"}}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                modules={[Autoplay]}
                breakpoints={{
                  992: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  576: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  200: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                }}
              >
                {loading ? (
                  <ProductsLoader length={2}/>
                ) : error ? (
                  <Message variant="danger">{error}</Message>
                ) : (
                  <>
                    {products.slice(0,4).map((product) => (
                      <SwiperSlide
                        className={styles.swiperSlide}
                        key={product._id}
                      >
                        <IdvProduct product={product} />
                      </SwiperSlide>
                    ))}
                  </>
                )}
              </Swiper>
            )}

            {swiper && (
              <Swiper
              style={{width:"100%"}}
                navigation={{
                  nextEl: ".next",
                  prevEl: ".prev",
                }}
                modules={[Navigation]}
                breakpoints={{
                  992: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  576: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  200: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                }}
              >
                {loading ? (
                  <ProductsLoader length={2}/>
                ) : error ? (
                  <Message variant="danger">{error}</Message>
                ) : (
                  <>
                    {products.map((product) => product.recommended && (
                      <SwiperSlide
                        className={styles.swiperSlide}
                        key={product._id}
                      >
                        <IdvProduct product={product} />
                      </SwiperSlide>
                    ))}
                  </>
                )}
              </Swiper>
            )}
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default CategoriesRow;
