import React, {useEffect, useState} from 'react'
import{Form, Container} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { getAccountRecoveryCode, recoveryLogin} from '../../actions/userActions'
import FormContainer from '../../components/FormContainer'


const ForgotPasswordScreen = () => {

    const [email, setEmail]= useState('')
    const [recoveryCode, setRecoveryCode]= useState('')
    const [codeForm, setCodeForm]=useState(false)
    const [displayPassword, setDisplayPassword]= useState(false)

    const dispatch = useDispatch()
    const history = useNavigate()

    const userLogin = useSelector((state)=> state.userLogin) 
    const {userInfo}= userLogin

    const userAccountRecoveryCode = useSelector((state)=> state.userAccountRecoveryCode) 
    const {error, success, loading}= userAccountRecoveryCode

    const userRecoveryLogin=useSelector((state)=>state.userRecoveryLogin)
    const {error:recLoginError, loading:recLoginLoading, success:recLoginSuccess}=userRecoveryLogin
    
if(recLoginSuccess){
    window.location.reload(false)
}

 useEffect(()=>{

    if(!error && success && loading===false){
        setCodeForm(true)
    }
    else{
        if(userInfo){
            history('/profile')
        }
    }
    
 },[dispatch, history, userInfo, error, success, loading])
  

const sendCodeformSubmitHander=(event)=>{
event.preventDefault()
dispatch(getAccountRecoveryCode({email}))
}  

 
const authCodeformSubmitHander=(event)=>{
    event.preventDefault()
    dispatch(recoveryLogin({email, rec_code:recoveryCode}))
}
   
const showPassword=()=>{
    setDisplayPassword(!displayPassword)
}

  return (
    <>
    <Container className='formScreen pt-5'>
<FormContainer>
       
      
       {!codeForm &&  
       <div>
        <h1>Account Recovery</h1>
        {error && <Message variant='danger'>{error}</Message>}
        {loading && <Loader />} 
    <Form as="form" className='form text-center' onSubmit={sendCodeformSubmitHander}>
         <Form.Group className='mb-3 text-start' controlId='email'>
        <Form.Label>Email Address</Form.Label>
        <Form.Control required type='email' placeholder='Enter Email' value={email}
         onChange={(event)=>setEmail(event.target.value)}></Form.Control>
      </Form.Group>
      <button className='mt-3' type='submit'>Send Code</button>
    </Form>
    </div>}

     {codeForm &&  
      <div>
      <h1>We have sent a code to your gmail</h1>
      {recLoginError && <Message variant='danger'>{recLoginError}</Message>}
        {recLoginLoading && <Loader />} 
   <Form as="form" className='form text-center' onSubmit={authCodeformSubmitHander}>
         <Form.Group className='mb-3 text-start' controlId='password'>
        <Form.Label>Enter Code</Form.Label>
        <Form.Control required type={!displayPassword ? 'password':'text'} placeholder='Enter Code' value={recoveryCode}
         onChange={(event)=>setRecoveryCode(event.target.value)}></Form.Control>
         <p className='text-end'>{!displayPassword ? 'Show':'Hide'}  <i className={!displayPassword ? 'fa-solid fa-eye':'fa-solid fa-eye-slash'} onClick={showPassword}></i></p>
      </Form.Group>
      <button className='mt-3' type='submit'>Submit</button>
     </Form>
     </div>}
</FormContainer>
</Container>
</>
    
  )
}

export default ForgotPasswordScreen